<template>
  <div class="flex flex-wrap">
    <label class="w-full text-a-text font-bold text-base">{{ label }}</label>
    <input
      :type="type"
      :placeholder="placeholder"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      class="cursor-pointer text-a-input form-select form-select-lg appearance-none block w-full px-4 py-4 text-sm font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded-3xl transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
    />
  </div>
</template>

<script>
export default {
  props: ["modelValue", "type", "label", "placeholder"],
};
</script>

<style lang="scss" scoped></style>
