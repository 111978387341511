<template>
  <div class="md:px-10 maxmd:pb-10">
    <button
      class="bg-l-btn font-sm text-l-black rounded py-1.5 px-4 flex justify-between"
    >
      <slot></slot>
    </button>
  </div>
</template>

<script></script>
<style></style>
