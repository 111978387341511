<template>
  <div class="">
    <div class="flex justify-between w-full">
      <p class="font-bold text-3xl text-a-text font-gilroy">
        {{ $t("students") }}
      </p>
      <div class="flex">
        <gradient-btn-vue color="bg-l-gradient" @click="toggleModal">{{
          $t("add")
        }}</gradient-btn-vue>
        <select
          class="px-4 text-2xl font-gilroy ml-3 mx-4 text-center justify-center border rounded-3xl border-a-link-b"
          name=""
          id=""
          :value="filter.year"
          v-model="filter.year"
        >
          <option value="2020" selected>2020</option>
          <option
            :value="item + 2020"
            v-for="item in new Date().getFullYear() - 2020"
          >
            {{ item + 2020 }}
          </option>
        </select>
      </div>
      <!-- <select
        class="border border-a-border rounded shadow p-2 text-a-text"
        name=""
        id=""
        @change="filter.year = parseInt($event.target.value)"
      >
        <option :value="index + 2010" :key="index" v-for="index in 12">
          {{ index + 2010 }}
        </option>
      </select> -->
    </div>
    <v-line-vue class="w-full" />
    <div class="flex items-center justify-end w-full">
      <div class="flex">
        <div class="flex items-center justify-between my-inputs mr-2">
          <span>{{ paginateDescription }}</span>
        </div>
        <paginate
          v-model="filter.page"
          :next-text="'>'"
          :prev-text="'<'"
          :page-range="1"
          :margin-pages="1"
          :page-count="pageCount"
          :container-class="'flex items-center'"
          :page-class="'mx-2 font-gilroy rounded border cursor-pointer border-a-border text-a-border py-1 px-3'"
        />
      </div>
    </div>
    <div class="flex maxmd:justify-center flex-wrap mt-10">
      <div class="flex items-center overflow-x-auto maxmd:max-w-7xl w-full">
        <p
          class="font-gilroy border text-center w-1/12 maxmd:w-20 h-full text-black font-bold border-a-border border-l-0 border-t-0 px-10 py-2.5 justify-between items-center flex"
        >
          №
        </p>
        <p
          @click="sort('full_name')"
          class="font-gilroy cursor-pointer font-bold justify-center text-center items-center w-2/12 maxmd:w-72 flex text-xs text-black p-2.5 border border-a-border border-t-0 border-l-0 h-full"
        >
          {{ $t("FIO") }}
        </p>
        <p
          class="font-gilroy font-bold justify-center text-center items-center w-2/12 flex maxmd:w-96 text-xs text-black p-2.5 border border-a-border border-l-0 border-t-0 h-full"
        >
          {{ $t("work") }}
        </p>
        <p
          @click="sort('nationality')"
          class="font-gilroy cursor-pointer font-bold justify-center text-center items-center w-1/12 flex maxmd:w-24 text-xs text-black p-2.5 border border-a-border border-l-0 border-t-0 h-full"
        >
          {{ $t("nationality") }}
        </p>
        <p
          class="font-gilroy font-bold justify-center text-center items-center text-xs maxmd:w-36 w-2/12 text-black flex flex-wrap p-2.5 border border-a-border border-l-0 border-t-0 h-full"
        >
          {{ $t("work_period") }}
        </p>
        <p
          @click="sort('date_of_birth')"
          class="font-gilroy font-bold cursor-pointer justify-center text-center items-center maxmd:w-44 w-2/12 text-xs text-black flex flex-wrap p-2.5 border border-a-border border-l-0 border-t-0 h-full"
        >
          {{ $t("date_birth") }}
        </p>
        <p
          class="font-gilroy font-bold justify-center text-xs maxmd:w-20 items-center w-1/12 flex text-black p-2.5 text-center border border-a-border border-l-0 border-t-0 h-full"
        >
          {{ $t("ended") }}
        </p>
        <p
          @click="sort('profile_rating')"
          class="font-gilroy font-bold cursor-pointer justify-center text-xs maxmd:w-48 items-center flex w-1/12 text-black p-2.5 text-center border border-a-border border-l-0 border-t-0 h-full border-r-0"
        >
          {{ $t("rating") }}
        </p>
      </div>
      <transition-group name="students">
        <v-student
          :item="item"
          :key="item.id"
          :number="(filter.page - 1) * filter.limit + index + 1"
          v-for="(item, index) in list"
        />
      </transition-group>
      <!-- <v-sudya-vue
        :item="item"
        :key="item.id"
        :number="(filter.page - 1) * filter.limit + index + 1"
        v-for="(item, index) in getStudents"
      /> -->
    </div>
    <v-modal-vue
      :modalActive="modalActive"
      @close-modal="toggleModal"
      :method="add"
      text="Добавить"
    >
      <h1 class="text-center font-bold w-full my-4 text-2xl font-gilroy">
        {{ $t("add_student") }}
      </h1>
      <auth-input-label-vue
        :value="student.name"
        :label="$t('name')"
        type="text"
        v-model="student.name"
        name="name"
      />
      <select
        class="text-2xl w-full font-gilroy p-2 my-2 text-center justify-center border rounded-3xl border-a-link-b"
        name=""
        id=""
        :value="student.year_graduate"
        v-model="student.year_graduate"
      >
        <option value="2020" selected>2020</option>
        <option
          :value="item + 2020"
          v-for="item in new Date().getFullYear() - 2020"
        >
          {{ item + 2020 }}
        </option>
      </select>
      <select
        class="cursor-pointer form-select form-select-lg appearance-none block w-full px-4 py-4 text-sm font-normal bg-white bg-clip-padding bg-no-repeat border border-solid border-a-text rounded-3xl transition ease-in-out m-0 focus:text-a-text focus:bg-white focus:outline-none"
        name=""
        v-model="student.id"
        id=""
      >
        <option :value="item.id" v-for="item in getJudges">
          {{ item.full_name }}
        </option>
      </select>
    </v-modal-vue>
  </div>
</template>
<script>
import vLineVue from "@/components/Admin/v-line.vue";
import vStudent from "@/components/Admin/v-student.vue";
import GradientBtnVue from "@/components/MainPage/GradientBtn.vue";
import vModalVue from "@/components/Admin/v-modal.vue";
import AuthInputLabelVue from "@/components/MainPage/AuthInputLabel.vue";
import vSudyaVue from "@/components/Admin/v-sudya.vue";
import vSelectVue from "@/components/Admin/v-select.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "IlkBor",
  computed: {
    ...mapGetters([
      "getStudents",
      "getStudyPlaces",
      "getDepartments",
      "getJudges",
    ]),
    pageCount() {
      return Math.ceil(
        parseInt(this.getStudents[0]?.total) / this.filter.limit
      );
    },
    paginateDescription() {
      let start = (this.filter.page - 1) * this.filter.limit;
      let end = start + this.filter.limit;
      let all = this.getStudents[0]?.total;
      if (all === undefined) all = 0;
      if (all <= end) end = all;

      return `${start}-${end} ${this.$t("from")} ${all}`;
    },
  },

  methods: {
    ...mapActions([
      "loadStudents",
      "loadDepartments",
      "addStudent",
      "loadJudges",
    ]),
    sort(par) {
      if (this.par === par) this.asc = !this.asc;
      else this.asc = true;
      this.par = par;
      if (par === "date_of_birth") {
        this.list = this.getStudents.sort((first, second) => {
          let arr = first[par].split("-");
          let date = arr[1] + "-" + arr[0] + "-" + arr[2];
          let arrs = second[par].split("-");
          let dates = arrs[1] + "-" + arrs[0] + "-" + arrs[2];
          if (this.asc) return new Date(dates) - new Date(date);
          return new Date(date) - new Date(dates);
        });
      } else if (par === "profile_rating") {
        this.list = this.getStudents.sort((first, second) => {
          if (this.asc)
            return (
              parseFloat(second.profile_rating.total) -
              parseFloat(first.profile_rating.total)
            );
          return (
            parseFloat(first.profile_rating.total) -
            parseFloat(second.profile_rating.total)
          );
        });
      } else if (
        par === "year_graduated" ||
        par === "work_experience_in_years"
      ) {
        this.list = this.getStudents.sort((first, second) => {
          if (this.asc) return parseFloat(second[par]) - parseFloat(first[par]);
          return parseFloat(first[par]) - parseFloat(second[par]);
        });
      } else {
        this.list = this.getStudents.sort((first, second) => {
          if (this.asc) return first[par]?.localeCompare(second[par]);
          return -1 * first[par]?.localeCompare(second[par]);
        });
      }
    },
    toggleModal() {
      this.modalActive = !this.modalActive;
    },
    add() {
      if (Object.keys(this.student).length > 2) {
        let obj = {};
        let year = this.student.year_graduate;
        obj = this.getJudges.filter((el) => {
          return el.id == this.student.id;
        });
        obj = obj[0];
        obj.year_graduate = year;
        this.addStudent(obj);
        this.toggleModal();
        this.student = {};
      }
    },
    changeP(event) {
      this.student.image = event.target.files[0];
    },
  },
  mounted() {
    this.loadStudents(this.filter);
    // this.loadDepartments();
  },

  data() {
    return {
      list: [],
      asc: true,

      modalActive: false,
      student: {
        year_graduate: 2020,
      },
      filter: {
        limit: 20,
        page: 1,
        year: 2020,
      },
      limits: [
        {
          key: 100,
          value: 100,
        },
        {
          key: 200,
          value: 200,
        },
        {
          key: 500,
          value: 500,
        },
        {
          key: 1000,
          value: 1000,
        },
      ],
    };
  },
  watch: {
    student: {
      handler(newValue, oldValue) {
        this.loadJudges({ limit: 10, page: 1, name: "" + newValue.name });
        // Note: `newValue` will be equal to `oldValue` here
        // on nested mutations as long as the object itself
        // hasn't been replaced.
      },
      deep: true,
    },
    filter: {
      handler(newValue, oldValue) {
        this.loadStudents(this.filter);
        // Note: `newValue` will be equal to `oldValue` here
        // on nested mutations as long as the object itself
        // hasn't been replaced.
      },
      deep: true,
    },
    getStudents: {
      handler(newValue, oldValue) {
        this.filter.year = newValue[0]?.year_graduated;
        this.list = newValue;
      },
      deep: true,
    },
  },
  components: {
    vLineVue,
    vStudent,
    GradientBtnVue,
    vModalVue,
    AuthInputLabelVue,
    vSelectVue,
    vSudyaVue,
  },
};
</script>
<style>
.students-move {
  transition: transform 0.3s ease;
}
</style>
