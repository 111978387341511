<template>
  <Teleport to="body">
    <Transition name="modal-outer">
      <form
        enctype="multipart/form-data"
        @submit.prevent="method"
        v-show="modalActive"
        class="fixed w-full overflow-x-hidden bg-l-black z-50 bg-opacity-30 h-screen top-0 left-0 flex justify-center px-8 items-center"
      >
        <Transition name="modal-inner">
          <div
            v-if="modalActive"
            class="rounded-xl h-4/6 overflow-x-hidden overflow-y-auto block p-10 bg-white self-start mt-32 md:max-w-xl w-full relative"
          >
            <div
              class="absolute right-4 top-2 text-5xl cursor-pointer rotate-45"
              @click="$emit('close-modal')"
            >
              +
            </div>
            <slot />
            <div class="w-full flex justify-center">
              <button
                type="submit"
                class="text-white bg-l-gradient mt-8 py-4 rounded-xl px-6 max-w-xs w-full mx-auto"
              >
                {{ text }}
              </button>
            </div>
          </div>
        </Transition>
      </form>
    </Transition>
  </Teleport>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Modal",

  computed: {},
  props: ["modalActive", "method", "text"],
  emits: ["close-modal"],
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.modal-outer-enter-active,
.modal-outer-leave-active {
  transition: opacity 0.5s cubic-bezier(0.52, 0.02, 0.19, 1.02);
}

.modal-outer-enter-from,
.modal-outer-leave-to {
  opacity: 0;
}
.modal-inner-enter-active {
  transition: all 0.5s cubic-bezier(0.52, 0.02, 0.19, 1.02) 0.15s;
}
.modal-inner-leave-acitve {
  transition: all 0.5s cubic-bezier(0.52, 0.02, 0.19, 1.02);
}
.modal-innner-enter-from {
  opacity: 0;
  transform: scale(0.8);
}
.modal-inner-enter-leave-to {
  transform: scale(0.8);
}
</style>
