<template>
  <div class="shadow-l-shadow rounded-3xl">
    <div
      class="bg-a-link-b items-center flex justify-between py-5 px-7 rounded-tl-3xl rounded-tr-3xl"
    >
      <div class="">
        <p class="text-xl text-white font-gilroy font-bold">Мехнат фаолияти</p>
        <p class="text-sm text-white font-gilroy">
          Судья ишлаган жойлари ва лавозимлари хакида малумот
        </p>
      </div>
      <div class="">
        <div class="cursor-pointer"></div>
      </div>
    </div>
    <div class="">
      <v-profile-info-item-vue v-for="item in work">
        <template v-slot:left>
          <div class="flex items-center">
            <img src="@/assets/mehnat_icon.svg" alt="" class="mr-2" />
            <div class="flex flex-col">
              <p class="font-gilroy text-lg text-a-black-2">
                {{ workName(item) }}
              </p>
              <p class="font-slalom text-a-profile-mehnat-g">
                {{ normal_date(item.start_date) }} -
                {{ normal_date(item.finish_date) }}
              </p>
            </div>
          </div>
        </template>
        <template v-slot:right
          ><p class="font-gilroy text-lg text-a-black-2"></p
        ></template>
      </v-profile-info-item-vue>
    </div>
  </div>
</template>

<script>
import vProfileInfoItemVue from "@/components/Admin/v-profile-info-item.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    vProfileInfoItemVue,
  },
  computed: {
    ...mapGetters(["work"]),
  },
  methods: {
    ...mapActions(["loadWork"]),
    workName(item) {
      if (item.court_name_uz == null) return item.staff_description;
      return item.court_name_uz;
    },
    normal_date(date) {
      if (date) {
        let temp = date;
        temp = temp.split("-");
        return temp[0] + "." + temp[1] + "." + temp[2];
      } else return "";
    },
  },
  mounted() {
    this.loadWork(this.$router.currentRoute.value.params.id);
  },
};
</script>

<style lang="scss" scoped></style>
