<template>
  <div class="flex">
    <div class="w-6/12">
      <p class="text-center font-bold font-gilroy text-2xl">
        {{ $t("rating_uzb") }}
      </p>
      <div
        class="flex w-full justify-between flex-wrap items-center overflow-hidden"
      >
        <div id="map" class="map w-full h-screen overflow-hidden"></div>
      </div>
    </div>
    <div class="w-6/12 flex flex-wrap">
      <div class="flex justify-center w-full">
        <select name="" id="" v-model="reg" :value="reg" class="cursor-pointer">
          <option value="Тошкент шаҳри">Тошкент шаҳри</option>
          <option value="Андижон вилояти">Андижон вилояти</option>
          <option value="Хоразм вилояти">Хоразм вилояти</option>
          <option value="Фарғона вилояти">Фарғона вилояти</option>
          <option value="Тошкент вилояти">Тошкент вилояти</option>
          <option value="Сирдарё вилояти">Сирдарё вилояти</option>
          <option value="Сурхондарё вилояти">Сурхондарё вилояти</option>
          <option value="Самарқанд вилояти">Самарқанд вилояти</option>
          <option value="Наманган вилояти">Наманган вилояти</option>
          <option value="Навоий вилояти">Навоий вилояти</option>
          <option value="Қашқадарё вилояти">Қашқадарё вилояти</option>
          <option value="Жиззах вилояти">Жиззах вилояти</option>
          <option value="Бухоро вилояти">Бухоро вилояти</option>
          <option value="Қорақалпоғистон Республикаси">
            Қорақалпоғистон Республикаси
          </option>
        </select>
      </div>
      <div
        id="chart_parent"
        class="flex w-full justify-between flex-wrap items-center overflow-hidden"
      >
        <div id="chart" class="chart w-full h-screen overflow-hidden"></div>
      </div>
    </div>
  </div>
</template>

<script>
import judges from "@/store/judges/judges";
import students from "@/store/judges/students";
import router from "@/router";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Dashboard",
  methods: {
    ...mapActions(["loadJudgesCount", "loadJudgesRating", "loadChartData"]),
    drawMap(arr) {
      if (arr.length !== 0) {
        var map = anychart.map();
        var data = arr;
        map.geoData(anychart.maps['uzbekistan']);

        // set the series
        var series = map.choropleth(data);
        series.hovered().fill("#2E6DF3");
        // disable labels
        series.labels(false);
        series.tooltip().format(function (e) {
          return "Рейтинг: " + e.getData("value");
        });
        // set the container
        map.container("map");
        map.draw();
      }
    },
    drawChart(arr) {
      if (arr.length !== 0) {
        var names = [
          "Ҳудуд рейтинги",
          "Маъмурий судлар",
          "Туманлараро маъмурий судлар судьялари",
          "Вилоят маъмурий суди судьялари",
          "Фуқаролик ишлари бўйича судьялар",
          "Жиноят ишлари бўйича судьялар",
          "Иқтисодий ишлар бўйича судьялар	",
          "Умумюрисдикция судлари",
        ];
        var data = arr;
        var dataSet = anychart.data.set(data);
        var palette = anychart.palettes
          .distinctColors()
          .items([
            "#64b5f6",
            "#1976d2",
            "#ef6c00",
            "#ffd54f",
            "#455a64",
            "#96a6a6",
            "#dd2c00",
            "#00838f",
            "#00838f",
            "#00838f",
            "#00bfa5",
            "#ffa000",
          ]);

        var makeBarWithBar = function (gauge, radius, i, width) {
          var stroke = null;
          gauge
            .label(i)
            .text(
              '<span style="color:#000">' +
                names[i] +
                "</span>" +
                '- <span style="color:#000;font-weight:bold">' +
                data[i] +
                " </span>"
            ) // color: #7c868e
            .useHtml(true);
          gauge
            .label(i)
            .hAlign("center")
            .vAlign("middle")
            .anchor("right-center")
            .padding(0, 10)
            .height(width / 2 + "%")
            .offsetY(radius + "%")
            .offsetX(0);

          gauge
            .bar(i)
            .dataIndex(i)
            .radius(radius)
            .width(width)
            .fill(palette.itemAt(i))
            .stroke(null)
            .zIndex(5);
          gauge
            .bar(i + 100)
            .dataIndex(5)
            .radius(radius)
            .width(width)
            .fill("#F5F4F4")
            .stroke(stroke)
            .zIndex(4);

          return gauge.bar(i);
        };

        var gauge = anychart.gauges.circular();
        gauge.data(dataSet);

        gauge
          .fill("#fff")
          .stroke(null)
          .padding(0)
          .margin(100)
          .startAngle(0)
          .sweepAngle(270);

        var axis = gauge.axis().radius(100).width(1).fill(null);
        axis
          .scale()
          .minimum(0)
          .maximum(100)
          .ticks({ interval: 1 })
          .minorTicks({ interval: 1 });
        axis.labels().enabled(false);
        axis.ticks().enabled(false);
        axis.minorTicks().enabled(false);
        makeBarWithBar(gauge, 120, 0, 15);
        makeBarWithBar(gauge, 105, 1, 15);
        makeBarWithBar(gauge, 90, 2, 15);
        makeBarWithBar(gauge, 75, 3, 15);
        makeBarWithBar(gauge, 60, 4, 15);
        makeBarWithBar(gauge, 45, 5, 15);
        makeBarWithBar(gauge, 30, 6, 15);
        makeBarWithBar(gauge, 15, 7, 15);

        gauge.margin(50);

        gauge
          .title()
          .text(
            "<span style='color:#000;font-size:22px'> Судьялар фаолияти самарадорлигини электрон рейтинг баҳолаш натижалари</span>" +
              `<br/><span style="color:#000; font-size: 16px;">(${this.reg})</span><br/><br/>`
          )
          .useHtml(true);
        gauge
          .title()
          .enabled(true)
          .hAlign("center")
          .padding(0)
          .margin([0, 0, 20, 0]);
        gauge.tooltip().format(function (e) {
          return "Рейтинг: " + e.getData("value");
        });

        gauge.container("chart");
        gauge.draw();
      }
    },
  },
  computed: {
    ...mapGetters(["judgesCount", "getJudgesRating", "chartData"]),
  },
  data() {
    return {
      reg: "Тошкент шаҳри",
    };
  },
  mounted() {
    this.loadJudgesCount();
    this.drawMap(this.judgesCount);
    this.loadChartData(this.reg);
  },
  watch: {
    judgesCount() {
      this.drawMap(this.judgesCount);
    },
    chartData() {
      this.drawChart(this.chartData);
    },
    reg() {
      document.querySelector("#chart").remove();
      document.querySelector("#chart_parent").innerHTML =
        "<div id='chart' class='chart w-full h-screen overflow-hidden'></div>";
      this.loadChartData(this.reg);
    },
  },
};
</script>
<style>
.anychart-credits {
  display: none !important;
}
</style>
