import axios from "axios";

export default {
  state: {
    students: [],
    study_places: [],
    login: {
      username: "dk",
      password: "zec123123",
    },
    departments: [],
  },
  getters: {
    getStudents(state) {
      return state.students;
    },
    getStudyPlaces(state) {
      return state.study_places;
    },
    getDepartments(state) {
      return state.departments;
    },
  },
  mutations: {
    updateStudyPlaces(state, data) {
      state.study_places = data;
    },
    updateStudents(state, data) {
      state.students = data;
    },
    updateDepartments(state, data) {
      let array = [];
      data.forEach((element) => {
        array.push({ key: element.id, value: element.title });
      });

      state.departments = array;
    },
  },
  actions: {
    login(context) {
      axios
        .post("https://e-justice.ai-softdev.com/auth/token/login/", context.state.login)
        .then((response) => {
          localStorage.setItem(
            "second_token",
            `Token ${response.data.auth_token}`
          );
        });
    },

    loadStudyPlaces(context) {
      let temp = [];
      temp.push({ key: "key 1", value: "value 1" });
      context.commit("updateStudyPlaces", temp);
    },
    addStudent(context, student) {
      axios
        .post("https://e-justice.ai-softdev.com/api/v1/sud/main/", {
          url: "http://185.203.238.157:7889/api/profile-avatars/select/main-photo",
          access_token: localStorage.getItem("token"),
          data: {
            profile_id: student.id,
          },
        })
        .then((response) => {
          console.log(response.data.result);
          let data = {
            student_id: student.id,
            photo: response.data.result.photo,
            year_graduated: parseInt(student.year_graduate),
          };
          axios
            .post("https://e-justice.ai-softdev.com/api/v1/student/", data, {
              headers: {
                Authorization: "Token " + localStorage.getItem("auth_token"),
              },
            })
            .then((response) => {
              context.dispatch("loadStudents", {
                page: 1,
                year: parseInt(student.year_graduate),
              });
              //...
            })
            .catch((e) => {
              //...
              console.log(e);
            });
        });
    },
    loadStudents(context, filter) {
      let config = {
        headers: {
          Authorization: "Token " + localStorage.getItem("auth_token"),
        },
      };
      axios
        .get(
          "https://e-justice.ai-softdev.com/api/v1/student/filter/" +
            filter.year +
            "?page=" +
            filter.page,
          config
        )
        .then((response) => {
          let array = [];
          if (response.data.students.length === 0) {
            context.commit("updateStudents", []);
          } else {
            response.data.students.forEach((el) => {
              axios
                .post("https://e-justice.ai-softdev.com/api/v1/sud/main/", {
                  url: "http://185.203.238.157:7889/api/profile/select/by-id",
                  access_token: localStorage.getItem("token"),
                  data: {
                    id: el.student_id,
                  },
                })
                .then((res) => {
                  res.data.result.photo = el.photo;
                  res.data.result.year_graduated = el.year_graduated;
                  res.data.result.total = response.data.total;
                  array.push(res.data.result);
                  console.log(res.data.result);
                  if (array.length === response.data.students.length) {
                    context.commit("updateStudents", array);
                  }
                });
            });
          }
        })
        .catch((er) => {
          //   localStorage.clear();
        });
    },
  },
};
