<template>
  <div class="">
    <button
      :class="color"
      class="text-white font-sm md:p-2 maxmd:p-3 rounded-lg px-4 flex justify-between items-center"
    >
      <slot></slot>
    </button>
  </div>
</template>

<script>
export default {
  props: ["color"],
};
</script>
<style></style>
