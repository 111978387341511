<template>
  <div>
    <div class="flex justify-between w-full flex-wrap">
      <div class="md:w-6/12 maxmd:w-full">
        <aos-vue animation="fade-right">
          <img src="@/assets/about_phone.webp" class="w-full" alt="" />
        </aos-vue>
      </div>

      <div
        class="md:w-6/12 md:py-10 md:px-5 maxmd:w-full flex items-center justify-center flex-wrap flex-col"
      >
        <aos-vue animation="fade-left">
          <h1
            class="text-bold text-l-bg lg:text-7xl maxlg:text-5xl text-justify font-kyiv"
          >
            {{ $t("about_project") }}
          </h1>
          <p class="mt-16 text-2xl text-l-ac text-justify font-gilroy">
            {{ $t("about_us_title") }}
          </p>
        </aos-vue>
      </div>

      <div
        class="md:w-6/12 md:py-10 md:px-5 maxmd:w-full maxmd:order-2 maxmd:-mt-36"
      >
        <aos-vue animation="fade-right">
          <p
            class="font-kyiv text-bold text-l-bg lg:text-7xl maxlg:text-5xl text-justify"
          >
            {{ $t("about_project_header") }}
          </p>
          <p class="mt-16 text-2xl text-l-ac text-justify font-gilroy">
            {{ $t("about_e-justice_title") }}
          </p>
          <p class="text-bold text-black text-2xl w-full my-10 font-gilroy">
            {{ $t("download_app") }}
          </p>
          <div
            class="flex w-full maxmd:flex-wrap justify-around items-center maxlg:flex-col"
          >
            <a
              class="w-full maxlg:mb-2 flex justify-center h-full maxmd:my-5 maxxl:w-6/12"
              href="https://apps.apple.com/us/app/e-justice/id6444007171"
            >
              <GradientBtnVue
                color="bg-l-gradient"
                class="xl:mr-5 rounded-xl flex flex-wrap items-center shadow-l-shadow"
              >
                <img
                  src="@/assets/App_Store.svg"
                  class="mr-1 w-[50px] h-[50px]"
                  alt=""
                />
                <p class="text-center lg:text-xl text-xs font-gilroy">
                  {{ $t("download_apple") }}
                </p>
              </GradientBtnVue>
            </a>
            <a
              class="w-full flex justify-center h-full items-center maxxl:w-6/12"
              href="https://play.google.com/store/apps/details?id=com.dikinaf.ejustice&pli=1"
            >
              <GradientBtnVue
                color="bg-l-gradient"
                class="flex flex-wrap rounded-xl items-center shadow-l-shadow"
              >
                <img src="@/assets/play.svg" class="w-[50px] h-[50px]" alt="" />
                <p class="lg:text-xl text-xs text-justify font-gilroy">
                  {{ $t("download_android") }}
                </p>
              </GradientBtnVue>
            </a>
          </div>
        </aos-vue>
      </div>
      <div class="md:w-6/12 maxmd:w-full">
        <aos-vue animation="fade-left">
          <img src="@/assets/phones.webp" class="w-full" alt="" />
        </aos-vue>
      </div>
    </div>
  </div>
</template>
<script>
import GradientBtnVue from "./GradientBtn.vue";

export default {
  name: "AboutApplication",
  components: {
    GradientBtnVue,
  },
};
</script>
