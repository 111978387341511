<template>
  <div
    class="relative flex flex-wrap flex-auto overflow-x-auto overflow-y-hidden w-full"
  >
    <!-- sidebar -->
    <div
      class="bg-a-h py-2 box-content w-screen h-14 items-center flex relative z-20"
    >
      <div class="h-full w-full flex justify-between items-center mx-14">
        <div class="flex justify-between items-center">
          <button
            @click="showSidebar = !showSidebar"
            class="text-white font-extrabold flex justify-end mr-5"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-10 h-10"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          </button>
          <div class="flex items-center text-white font-gilroy">
            <img src="@/assets/logo_2.svg" alt="" class="mr-4" />
          </div>
        </div>
        <div class="flex items-center">
          <!-- <v-dropdown-vue placement="right"> -->
          <!-- <template v-slot:button> -->
          <img
            class="w-10 h-10 rounded-full object-cover"
            src="@/assets/default.jpg"
            alt=""
          />
          <div class="flex flex-col ml-2 justify-center items-center">
            <span class="text-white font-gilroy">{{ $t("admin") }}</span>
            <!-- <span class="text-cyan-200">position</span> -->
          </div>
          <!-- <svg
            class="w-4 h-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path
              d="M4.516 7.548c.436-.446 1.043-.481 1.576 0L10 11.295l3.908-3.747c.533-.481 1.141-.446 1.574 0 .436.445.408 1.197 0 1.615-.406.418-4.695 4.502-4.695 4.502a1.095 1.095 0 0 1-1.576 0S4.924 9.581 4.516 9.163c-.409-.418-.436-1.17 0-1.615z"
            />
          </svg> -->
          <!-- </template> -->
          <!-- <template v-slot:content>
              <a
                class="flex w-full justify-between items-center rounded px-2 py-1 my-1 hover:bg-indigo-600 hover:text-white"
                href="#"
                >Profile</a
              >
              <a
                class="flex w-full justify-between items-center rounded px-2 py-1 my-1 hover:bg-indigo-600 hover:text-white"
                href="#"
                >Settings</a
              >
              <a
                class="flex w-full justify-between items-center rounded px-2 py-1 my-1 hover:bg-indigo-600 hover:text-white"
                href="#"
                >Help</a
              >
              <v-line-vue />

              <button
                @click="logout"
                class="flex w-full justify-between items-center rounded px-2 py-1 my-1 hover:bg-red-600 hover:text-white"
              >
                Logout
              </button>
            </template> -->
          <!-- </v-dropdown-vue> -->
        </div>
      </div>
    </div>

    <div
      class="bg-a-nav-b pt-10 absolute text-cyan-100 min-h-full px-6 z-10 transition duration-200 ease"
      :class="{
        '  translate-x-0  top-14  box-content ': showSidebar,
        '  -translate-x-full top-14  ': !showSidebar,
      }"
    >
      <!-- <a href="" class="flex items-center">
        Logo
        <span class="text-2xl font-extrabold text-black">Name </span>
      </a> -->
      <!-- <v-select-vue :items="headerSelect" v-model="selected" /> -->
      <v-line-vue />
      <span
        @click="changeLang"
        class="text-2xl cursor-pointer w-full text-center block text-white font-gilroy font-bold px-4"
        >{{ lang }}</span
      >
      <nav>
        <router-link :to="{ name: 'dashboard' }" @click="showSidebar = false">
          <v-link-vue :item="$t('main')" :active="CurrentRoute('dashboard')">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.5 14.6037C2.5 12.4636 2.5 11.3935 2.90671 10.4477C2.96615 10.3095 3.03179 10.1741 3.10344 10.0418C3.59373 9.13653 4.43362 8.47346 6.11338 7.14733V7.14733C8.78372 5.03917 10.1189 3.98509 11.6661 3.88154C11.8885 3.86666 12.1115 3.86666 12.3339 3.88154C13.8811 3.98509 15.2163 5.03917 17.8866 7.14733V7.14733C19.5664 8.47346 20.4063 9.13653 20.8966 10.0418C20.9682 10.1741 21.0339 10.3095 21.0933 10.4477C21.5 11.3935 21.5 12.4636 21.5 14.6037V16C21.5 18.8284 21.5 20.2426 20.6213 21.1213C19.7426 22 18.3284 22 15.5 22H12H8.5C5.67157 22 4.25736 22 3.37868 21.1213C2.5 20.2426 2.5 18.8284 2.5 16V14.6037Z"
                stroke="#7D8087"
                stroke-width="1.4"
              />
              <path
                d="M15 22C15 21.606 14.9224 21.2159 14.7716 20.8519C14.6209 20.488 14.3999 20.1573 14.1213 19.8787C13.8427 19.6001 13.512 19.3791 13.1481 19.2284C12.7841 19.0776 12.394 19 12 19C11.606 19 11.2159 19.0776 10.8519 19.2284C10.488 19.3791 10.1573 19.6001 9.87868 19.8787C9.6001 20.1573 9.37913 20.488 9.22836 20.8519C9.0776 21.2159 9 21.606 9 22L12 22H15Z"
                stroke="#7D8087"
                stroke-width="1.4"
              />
            </svg> </v-link-vue
        ></router-link>
        <router-link :to="{ name: 'ilkbor' }" @click="showSidebar = false">
          <v-link-vue :item="$t('students')" :active="CurrentRoute('ilkbor')">
            <svg
              width="24"
              height="24"
              viewBox="0 0 15 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.25 6.60659C11.5625 6.60659 11.875 6.91909 11.875 7.23159C11.875 7.58315 11.5625 7.85659 11.25 7.85659H3.75C3.39844 7.85659 3.125 7.58315 3.125 7.23159C3.125 6.91909 3.39844 6.60659 3.75 6.60659H11.25ZM3.125 14.7316C3.125 14.4191 3.39844 14.1066 3.75 14.1066H11.25C11.5625 14.1066 11.875 14.4191 11.875 14.7316C11.875 15.0832 11.5625 15.3566 11.25 15.3566H3.75C3.39844 15.3566 3.125 15.0832 3.125 14.7316ZM11.25 10.3566C11.5625 10.3566 11.875 10.6691 11.875 10.9816C11.875 11.3332 11.5625 11.6066 11.25 11.6066H3.75C3.39844 11.6066 3.125 11.3332 3.125 10.9816C3.125 10.6691 3.39844 10.3566 3.75 10.3566H11.25ZM3.08594 2.97378L4.84375 1.1769C5.07812 0.942528 5.50781 0.942528 5.74219 1.1769L7.5 2.93472L9.21875 1.1769C9.45312 0.942528 9.88281 0.942528 10.1172 1.1769L11.875 2.97378L13.9453 1.13784C14.1406 0.98159 14.375 0.942528 14.6094 1.05972C14.8438 1.1769 15 1.37222 15 1.60659V20.3566C15 20.63 14.8438 20.8253 14.6094 20.9425C14.375 21.0597 14.1406 21.0207 13.9453 20.8253L11.875 19.0285L10.1172 20.8253C9.88281 21.0597 9.45312 21.0597 9.21875 20.8253L7.5 19.0675L5.74219 20.8253C5.50781 21.0597 5.07812 21.0597 4.84375 20.8253L3.08594 19.0285L1.01562 20.8253C0.820312 21.0207 0.585938 21.0597 0.351562 20.9425C0.117188 20.8253 0 20.63 0 20.3566V1.60659C0 1.37222 0.117188 1.1769 0.351562 1.05972C0.585938 0.942528 0.820312 0.98159 1.01562 1.13784L3.08594 2.97378ZM2.69531 17.7003C2.92969 17.505 3.32031 17.505 3.55469 17.7394L5.3125 19.4972L7.03125 17.7394C7.26562 17.505 7.69531 17.505 7.92969 17.7394L9.6875 19.4972L11.4062 17.7394C11.6406 17.505 12.0312 17.505 12.2656 17.7003L13.75 18.9894V3.01284L12.2656 4.3019C12.0312 4.49722 11.6406 4.49722 11.4062 4.26284L9.6875 2.50503L7.92969 4.26284C7.69531 4.49722 7.26562 4.49722 7.03125 4.26284L5.3125 2.50503L3.55469 4.26284C3.32031 4.49722 2.92969 4.49722 2.69531 4.3019L1.25 3.01284V18.9894L2.69531 17.7003Z"
                fill="#7D8187"
              />
            </svg>
          </v-link-vue>
        </router-link>

        <router-link :to="{ name: 'search' }" @click="showSidebar = false">
          <v-link-vue :item="$t('search')" :active="CurrentRoute('search')">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.40403 17.3222C4.44068 16.9879 4.45901 16.8208 4.48196 16.7015C4.76052 15.2543 5.93111 14.2799 7.40491 14.2686C7.52634 14.2677 7.71595 14.2816 8.09516 14.3095C9.27296 14.3959 10.5997 14.4667 11.7861 14.4667C13.0244 14.4667 14.3976 14.3896 15.5914 14.298C15.9571 14.27 16.1399 14.256 16.2588 14.2563C17.6551 14.2601 18.7831 15.1264 19.147 16.4745C19.178 16.5893 19.2076 16.748 19.2668 17.0653C19.3321 17.4153 19.3647 17.5902 19.3774 17.7324C19.5246 19.3759 18.3169 20.8298 16.6742 20.9865C16.5321 21 16.3541 21 15.9982 21L7.70058 21C7.37668 21 7.21474 21 7.08232 20.9882C5.51066 20.8487 4.31572 19.5155 4.34832 17.938C4.35106 17.8051 4.36872 17.6441 4.40403 17.3222Z"
                fill="#7D8187"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16.2575 14.7563C16.1639 14.756 16.008 14.7676 15.6296 14.7966C14.4299 14.8885 13.0425 14.9667 11.7861 14.9667C10.5823 14.9667 9.24199 14.895 8.05855 14.8081C7.66622 14.7793 7.5042 14.7679 7.40873 14.7686C6.17319 14.7781 5.20647 15.5827 4.97294 16.7961C4.95485 16.89 4.93898 17.0307 4.90104 17.3767C4.86451 17.7097 4.85037 17.8433 4.8482 17.9483C4.82104 19.2629 5.81682 20.3739 7.12654 20.4902C7.23123 20.4995 7.36552 20.5 7.70057 20.5L15.9982 20.5C16.3665 20.5 16.5145 20.4994 16.6267 20.4887C17.9956 20.3582 19.0021 19.1466 18.8794 17.777C18.8694 17.6647 18.8428 17.5191 18.7753 17.157C18.714 16.8286 18.6887 16.6953 18.6643 16.6048C18.3592 15.4744 17.4283 14.7595 16.2575 14.7563ZM15.5911 13.7966C15.9213 13.7712 16.1211 13.7559 16.2602 13.7563C17.882 13.7607 19.2071 14.7785 19.6297 16.3442C19.666 16.4784 19.6988 16.6546 19.7524 16.942L19.7646 17.0073C19.8239 17.3252 19.8605 17.5216 19.8754 17.6878C20.0472 19.6052 18.6381 21.3014 16.7216 21.4842C16.5556 21.5 16.3558 21.5 16.0325 21.5L7.66966 21.5C7.3752 21.5 7.19288 21.5 7.03808 21.4863C5.20448 21.3235 3.81038 19.7681 3.84842 17.9277C3.85163 17.7723 3.87151 17.5911 3.90363 17.2984C3.90474 17.2883 3.90586 17.278 3.907 17.2677C3.90824 17.2563 3.90947 17.2451 3.91068 17.2341C3.94384 16.9315 3.96413 16.7465 3.99097 16.607C4.31456 14.9258 5.68902 13.7818 7.40107 13.7687C7.54308 13.7676 7.74987 13.7828 8.09217 13.8079L8.13174 13.8108C9.30391 13.8968 10.617 13.9667 11.7861 13.9667C13.0062 13.9667 14.3652 13.8906 15.5531 13.7995L15.5911 13.7966Z"
                fill="#7D8187"
              />
              <path
                d="M8.00068 7C8.00068 4.79086 9.79154 3 12.0007 3C14.2098 3 16.0007 4.79086 16.0007 7V7.57143C16.0007 9.46498 14.4657 11 12.5721 11H11.4293C9.5357 11 8.00068 9.46498 8.00068 7.57143V7Z"
                fill="#7D8187"
              />
            </svg>
          </v-link-vue>
        </router-link>
        <router-link :to="{ name: 'sobik' }" @click="showSidebar = false">
          <v-link-vue
            :item="$t('search_sobik')"
            :active="CurrentRoute('sobik')"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 18 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 0H2.5C1.09375 0 0 1.13281 0 2.5V17.5C0 18.9062 1.09375 20 2.5 20H15C16.3672 20 17.5 18.9062 17.5 17.5V2.5C17.5 1.13281 16.3672 0 15 0ZM16.25 17.5C16.25 18.2031 15.6641 18.75 15 18.75H2.5C1.79688 18.75 1.25 18.2031 1.25 17.5V10.625H16.25V17.5ZM16.25 9.375H1.25V2.5C1.25 1.83594 1.79688 1.25 2.5 1.25H15C15.6641 1.25 16.25 1.83594 16.25 2.5V9.375ZM11.875 3.75H5.625C5.27344 3.75 5 4.0625 5 4.375V5.625C5 5.97656 5.27344 6.25 5.625 6.25C5.9375 6.25 6.25 5.97656 6.25 5.625V5H11.25V5.625C11.25 5.97656 11.5234 6.25 11.875 6.25C12.1875 6.25 12.5 5.97656 12.5 5.625V4.375C12.5 4.0625 12.1875 3.75 11.875 3.75ZM5.625 16.25C5.9375 16.25 6.25 15.9766 6.25 15.625V15H11.25V15.625C11.25 15.9766 11.5234 16.25 11.875 16.25C12.1875 16.25 12.5 15.9766 12.5 15.625V14.375C12.5 14.0625 12.1875 13.75 11.875 13.75H5.625C5.27344 13.75 5 14.0625 5 14.375V15.625C5 15.9766 5.27344 16.25 5.625 16.25Z"
                fill="#7D8187"
              />
            </svg>
          </v-link-vue>
        </router-link>
        <v-line-vue />
        <!-- <router-link to="/" > -->
        <p
          @click="logout"
          class="flex cursor-pointer items-center py-3 px-4 space-x-2 hover:bg-cyan-700 rounded duration-300"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.8047 10.0703L14.1797 4.44531C13.9453 4.21094 13.5156 4.21094 13.2812 4.44531C13.0469 4.67969 13.0469 5.10938 13.2812 5.34375L17.8516 9.875H6.875C6.52344 9.875 6.25 10.1875 6.25 10.5C6.25 10.8516 6.52344 11.125 6.875 11.125H17.8516L13.2812 15.6953C13.0469 15.9297 13.0469 16.3594 13.2812 16.5938C13.5156 16.8281 13.9453 16.8281 14.1797 16.5938L19.8047 10.9688C19.9219 10.8516 20 10.6953 20 10.5C20 10.3438 19.9219 10.1875 19.8047 10.0703ZM6.875 18H3.125C2.07031 18 1.25 17.1797 1.25 16.125V4.875C1.25 3.85938 2.07031 3 3.125 3H6.875C7.1875 3 7.5 2.72656 7.5 2.375C7.5 2.0625 7.1875 1.75 6.875 1.75H3.125C1.36719 1.75 0 3.15625 0 4.875V16.125C0 17.8828 1.36719 19.25 3.125 19.25H6.875C7.1875 19.25 7.5 18.9766 7.5 18.625C7.5 18.3125 7.1875 18 6.875 18Z"
              fill="#CF4F66"
            />
          </svg>

          <span class="text-a-red">{{ $t("exit") }}</span>
        </p>
        <!-- </router-link> -->
      </nav>
    </div>

    <div class="flex-1 min-h-screen">
      <div class="p-8">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
import vDropdownVue from "@/components/Admin/v-dropdown.vue";
import vSelectVue from "@/components/Admin/v-select.vue";
import vLinkVue from "@/components/Admin/v-link.vue";
import router from "@/router";
import { useI18n } from "vue-i18n";

import vLineVue from "@/components/Admin/v-line.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  setup() {
    const { locale, t } = useI18n({ useScope: "global" });
    localStorage.setItem("locale", "" + locale.value);

    return {
      switchLanguage: () => {
        locale.value = locale.value === "uz" ? "ru" : "uz";
        localStorage.setItem("locale", "" + locale.value);
      },
    };
  },
  computed: {
    ...mapActions(["CurrentUser"]),
    ...mapGetters(["Name", "isAuth"]),
  },
  mounted() {
    if (localStorage.getItem("token")) {
      this.CurrentUser;
    } else {
      router.push("/");
    }
    if (!this.isAuth) {
      router.push("/");
    }
  },
  watch: {
    isAuth() {
      if (!this.isAuth) {
        router.push("/");
      }
    },
  },
  data() {
    return {
      showSidebar: false,
      selected: "",
      lang: localStorage.getItem("locale"),
    };
  },
  methods: {
    CurrentRoute(name) {
      return this.$router.currentRoute.value.name === name;
    },
    logout() {
      localStorage.clear();
      router.push("/");
    },
    changeLang() {
      this.switchLanguage();
      this.lang = localStorage.getItem("locale");
    },
  },
  watch: {
    lang() {
      console.log(this);
    },
  },
  components: { vDropdownVue, vSelectVue, vLinkVue, vLineVue },
};
</script>
<style></style>
