<template>
  <div class="border-t-l-border border-t py-5 mt-2">
    <div class="text-center font-slalom">
      <aos-vue
        animation="fade-up"
        class="container text-center m-auto flex justify-center items-center maxlg:flex-wrap"
      >
        © {{ new Date().getFullYear() }}. {{ $t("footer_text") }}
      </aos-vue>
    </div>
    <!-- <div class="flex flex-wrap lg:justify-between maxlg:justify-center">
        <a
          href="tel:+998 91 999 99 32"
          class="flex items-center mr-10 text-bold text-l-bg text-xl maxlg:w-full justify-center"
        >
          <img src="@/assets/phone.svg" alt="" class="mr-2 font-slalom" />
          <span>+998 91 999 99 32</span>
        </a>
        <a
          href="mailto:judgeschool@gmail.com"
          class="flex items-center text-bold text-l-bg text-xl maxlg:w-full justify-center maxlg:my-5"
        >
          <img src="@/assets/mail_icon_b.svg" alt="" class="mr-2 font-slalom" />
          <span>judgeschool@gmail.com</span>
        </a>
      </div> -->
  </div>
</template>
