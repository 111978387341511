<template>
  <div>
    <div class="relative" id="scrollarea">
      <img
        src="@/assets/inner.webp"
        alt=""
        class="absolute -z-10 h-full w-full"
      />
      <img
        src="@/assets/inner_3.webp"
        alt=""
        class="absolute -z-10 h-full top-0 right-0"
      />
      <div>
        <LHeader
          :max-scroll="maxScroll"
          :modalActive="toggleModal"
          :modalHelp="toggleHelp"
          :switch-language="switchLanguage"
        />
      </div>

      <aos-vue animation="fade-up">
        <div
          id="maxScroll"
          class="flex pt-16 justify-between container m-auto items-center px-4 maxmd:flex-wrap maxmd:justify-center"
        >
          <div class="w-6/12 maxmd:w-full maxmd:order-1 py-10">
            <h1 class="text-l-inner text-5xl font-bold font-inner font-kyiv">
              {{ $t("intro_main") }}
            </h1>
            <h4 class="text-l-mc text-2xl font-normal my-20 font-gilroy">
              {{ $t("intro_sub") }}
            </h4>
            <!-- <gradient-btn color="bg-l-btn-more">
              <span class="text-xl mr-1 font-slalom">{{
                $t("intro_but")
              }}</span>
              <img src="@/assets/arrow.svg" class="w-7 h-7" alt="" />
            </gradient-btn> -->
          </div>
          <div class="w-6/12 maxmd:w-full mt-2">
            <img src="@/assets/inner_2.webp" alt="" class="w-full h-full" />
          </div>
        </div>
      </aos-vue>
    </div>
    <div class="container m-auto px-4" id="info-scroll">
      <aos-vue animation="fade-left">
        <div class="flex justify-center flex-wrap my-10">
          <info-block v-for="item in infoList" :item="item" />

          <div
            class="flex flex-wrap max-w-xs border-r h-full border-r-l-border px-6 my-3"
          >
            <h3 class="font-bold text-3xl text-center w-full font-kyiv h-full">
              {{ $t("info_4_h") }}
            </h3>
            <div class="w-full flex justify-between">
              <div class="text-center font-kyiv font-bold text-xl w-full">
                {{ $t("info_4_1") }}
              </div>
              <div class="text-center font-kyiv font-bold text-xl w-full">
                {{ $t("info_4_2") }}
              </div>
            </div>
            <div class="w-full flex justify-between">
              <div
                class="text-l-mc text-4xl text-center w-full font-bold font-kyiv"
              >
                <number
                  animationPaused
                  ref="number_1"
                  :from="0"
                  :to="1138"
                  :format="theFormat"
                  :duration="3"
                  easing="Power1.easeOut"
                />
              </div>
              <div
                class="text-l-mc text-4xl text-center w-full font-bold font-kyiv"
              >
                <number
                  animationPaused
                  ref="number"
                  :from="0"
                  to="514"
                  :format="theFormat"
                  :duration="3"
                  easing="Power1.easeOut"
                />
              </div>
            </div>
          </div>
        </div>
      </aos-vue>
      <div class="mb-10 block h-9" id="about_school"></div>
      <aos-vue animation="fade-right">
        <p
          class="font-kyiv text-l-inner md:text-7xl maxmd:text-5xl font-bold my-10"
        >
          {{ $t("about_project") }}
        </p>
        <div class="flex my-10 maxmd:flex-wrap">
          <img src="@/assets/about.webp" alt="" class="md:w-4/12 maxmd:w-full" />
          <div class="md:p-8 md:w-8/12 bg-l-bg md:px-12 maxmd:p-4">
            <p class="font-bold text-6xl text-white font-kyiv">
              {{ $t("about_project_header") }}
            </p>
            <p class="text-white text-2xl mt-6 mb-16 font-gilroy">
              {{ $t("about_project_title") }}
            </p>
            <!-- <gradient-btn color="bg-l-btn-school">
              <span class="text-xl mr-1 font-slalom">{{
                $t("about_project_button")
              }}</span>
              <img src="@/assets/arrow.svg" class="w-7 h-7" alt="" />
            </gradient-btn> -->
          </div>
        </div>
      </aos-vue>
      <aos-vue animation="fade-up">
        <p
          class="font-kyiv text-l-inner md:text-7xl maxmd:text-5xl font-bold my-10"
        >
          {{ $t("video") }}
        </p>
        <div class="md:p-12 maxmd:p-4">
          <video
            loop
            class="w-full"
            src="@/assets/video/promo.mp4"
            autoplay="true"
            muted="true"
            controls="true"
          />
        </div>
      </aos-vue>
      <div class="mb-10 block h-9" id="services"></div>

      <div class="my-10">
        <aos-vue animation="fade-left">
          <p
            class="font-kyiv text-l-inner md:text-7xl maxmd:text-5xl font-bold my-10"
          >
            {{ $t("FAQ") }}
          </p>
        </aos-vue>
        <aos-vue animation="fade-right">
          <div class="relative overflow-hidden">
            <accordion-item-vue
              v-for="(item, index) in accordionList"
              :item="item"
              :index="index + 1"
            />
          </div>
        </aos-vue>
      </div>
      <about-application-vue />
    </div>
    <FooterVue />
    <auth-modal-vue :modalActive="modalActive" @close-modal="toggleModal" />
    <v-modal-vue
      :modalActive="helpActive"
      @close-modal="toggleHelp"
      :method="sendHelp"
      :text="$t('form_button')"
    >
      <p class="font-gilroy text-3xl text-black">{{ $t("form_first") }}</p>
      <p class="font-gilroy text-2xl text-l-i-text mb-5">
        {{ $t("form_second") }}
      </p>
      <v-input-vue
        class="my-3"
        :placeholder="$t('form_place_first')"
        type="text"
        v-model="help.name"
      />
      <v-input-vue
        class="my-3"
        :placeholder="$t('form_place_second')"
        type="text"
        v-model="help.phone"
      />
      <v-input-vue
        class="my-3"
        :placeholder="$t('form_place_third')"
        type="textarea"
        v-model="help.text"
      />
    </v-modal-vue>
  </div>
</template>

<script>
import LHeader from "@/components/MainPage/LHeader.vue";
import GradientBtn from "@/components/MainPage/GradientBtn.vue";
import InfoBlock from "@/components/MainPage/AboutInfoBlock.vue";
import AccordionItemVue from "@/components/MainPage/AccordionItem.vue";
import AboutApplicationVue from "@/components/MainPage/AboutApplication.vue";
import FooterVue from "@/components/MainPage/Footer.vue";
import AuthModalVue from "@/components/MainPage/AuthModal.vue";
import vModalVue from "@/components/Admin/v-modal.vue";
import vInputVue from "@/components/MainPage/v-input.vue";
import router from "@/router";
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import { useI18n } from "vue-i18n";

export default {
  name: "Home",
  setup() {
    const { locale, t } = useI18n({ useScope: "global" });
    localStorage.setItem("locale", "" + locale.value);

    return {
      switchLanguage: () => {
        locale.value = locale.value === "uz" ? "ru" : "uz";
        localStorage.setItem("locale", "" + locale.value);
      },
    };
  },
  components: {
    LHeader,
    GradientBtn,
    InfoBlock,
    AccordionItemVue,
    AboutApplicationVue,
    FooterVue,
    AuthModalVue,
    vModalVue,
    vInputVue,
  },
  data() {
    return {
      maxScroll: 0,
      help: {},
      helpActive: false,
      modalActive: false,
      scrollPosition: null,
      maxScroll_2: null,
      played: false,
    };
  },
  computed: {
    ...mapGetters(["isAuth"]),
    infoList() {
      return [
        {
          header: this.$t("info_1_h"),
          num: "1331",
          bottom: "",
        },
        {
          header: this.$t("info_2_h"),
          num: "207",
          bottom: "",
        },
        {
          header: this.$t("info_3_h"),
          num: "51",
          bottom: "",
        },
      ];
    },
    accordionList() {
      return [
        {
          title: this.$t("faq_1"),
          body: this.$t("faq_1_text"),
        },
        {
          title: this.$t("faq_2"),
          body: this.$t("faq_2_text"),
        },
      ];
    },
  },
  methods: {
    ...mapActions(["Auth", "currenUser"]),
    toggleModal() {
      this.modalActive = !this.modalActive;
    },
    toggleHelp() {
      this.helpActive = !this.helpActive;
    },
    sendHelp() {},
    theFormat(number) {
      return number.toFixed(0);
    },
    playAnimation() {
      this.$refs.number.play();
      this.$refs.number_1.play();
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
      if (this.played === false) {
        if (this.scrollPosition > this.maxScroll_2 - 300) {
          this.playAnimation();
          this.played = true;
        }
      }
    },
  },
  mounted() {
    this.currenUser;
    this.maxScroll = document.querySelector("#maxScroll").clientHeight;
    if (this.isAuth) router.push("/admin/dashboard");
    this.maxScroll_2 = document.querySelector("#info-scroll").offsetTop;
    window.addEventListener("scroll", this.updateScroll);
    this.scrollPosition = window.scrollY;
    if (window.outerHeight > this.maxScroll_2 + 260) {
      this.playAnimation();
      this.played = true;
    }
    // axios.get("https://e-justice.ai-softdev.com/api/v1/faq").then((response) => {
    //   console.log(response.data);
    //   this.accordionList = response.data;
    // });
  },
  watch: {
    isAuth() {
      if (this.isAuth) router.push("/admin/dashboard");
    },
  },
};
</script>
<style>
body {
  overflow: hidden;
}
</style>
