import { createRouter, createWebHistory } from 'vue-router'
import IndexView from '@/views/Index.vue'
import AdminView from '@/views/Admin/Admin.vue'
import SearchView from '@/views/Admin/search.vue';
import DashboardView from '@/views/Admin/dashboard.vue'
import IlkBorView from '@/views/Admin/ilkBor.vue'
import SobikSudylarView from '@/views/Admin/SobikSudyalar.vue'
import ProfileView from '@/views/Admin/Profile.vue'
import mainView from '@/views/Admin/profile/main.vue'
import ratingView from '@/views/Admin/profile/rating.vue'
import mehnatView from '@/views/Admin/profile/mehnat.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: IndexView
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminView,
    children:[
      {
        path: 'search',
        name: 'search',
        component: SearchView,
      },
      {
        path: 'dashboard',
        name: 'dashboard',
        component: DashboardView,
      },
      {
        path: 'sobik',
        name: 'sobik',
        component: SobikSudylarView,
      },
      {
        path: 'ilk-bor',
        name: 'ilkbor',
        component: IlkBorView,
      },
      {
        path:'profile/:id',
        name:'profile',
        component:ProfileView,
        
        children:[
          {
            path:'umumiy-malumotlar',
            name:'main',
            component:mainView,
          },
          {
            path:'rating',
            name:'rating',
            component:ratingView
          },
          {
            path:'mehnat',
            name:'mehnat',
            component:mehnatView
          }
        ]
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
