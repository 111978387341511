import { createApp } from "vue";
import App from "./App.vue";
import router from "./router.js";
import store from "./store";
import "./styles/app.css";
import Paginate from "vuejs-paginate-next";
import VueNumber from "vue-number-animation";
import AosVue from "aos-vue";
import "chartkick/chart.js";

import { languages } from "./lang";
import { defaultLocale } from "./lang";

import { createI18n } from "vue-i18n";

const localeLocalStorage = localStorage.getItem("locale");

const messages = Object.assign(languages);
const i18n = createI18n({
  legacy: false,
  locale: localeLocalStorage || defaultLocale,
  fallbackLocale: "uz",
  messages,
});
const app = createApp(App);
app.component("Paginate", Paginate);
app.use(store);
app.use(i18n);
app.use(router);
app.use(VueNumber);
app.use(AosVue);
app.mount("#app");
