<template>
  <Teleport to="body">
    <Transition name="modal-outer">
      <form
        @submit.prevent="auth"
        v-show="modalActive"
        class="fixed w-full bg-l-black z-50 bg-opacity-30 h-screen top-0 left-0 flex justify-center px-8 items-center"
      >
        <Transition name="modal-inner">
          <div
            v-if="modalActive"
            class="rounded-xl block p-10 bg-white self-start mt-32 md:max-w-xl w-full relative"
          >
            <div
              class="absolute right-4 top-2 text-5xl cursor-pointer rotate-45"
              @click="$emit('close-modal')"
            >
              +
            </div>
            <h1 class="text-l-black text-center text-2xl font-bold my-4">
              {{ $t("auth") }}
            </h1>
            <auth-input-label-vue
              :value="user.username"
              :label="$t('login')"
              type="text"
              v-model="user.username"
              name="username"
            />
            <auth-input-label-vue
              :value="user.password"
              v-model="user.password"
              :label="$t('password')"
              type="password"
              name="password"
            />
            <div class="w-full flex justify-center">
              <button
                type="submit"
                class="text-white bg-l-gradient mt-8 py-4 rounded-xl px-6 max-w-xs w-full mx-auto"
              >
                {{ $t("auth") }}
              </button>
            </div>
          </div>
        </Transition>
      </form>
    </Transition>
  </Teleport>
</template>

<script>
import AuthInputLabelVue from "./AuthInputLabel.vue";
import { mapGetters } from "vuex";
export default {
  name: "AuthModal",
  components: {
    AuthInputLabelVue,
  },
  computed: {
    ...mapGetters(["isAuth"]),
  },
  props: ["modalActive"],
  emits: ["close-modal"],
  data() {
    return {
      user: {
        username: "",
        password: "",
      },
    };
  },
  methods: {
    auth() {
      this.$store.dispatch("Auth", this.user);
      setTimeout((e) => {
        if (this.isAuth) this.$emit("close-modal", false);
      }, 300);
      // window.location.reload();
    },
  },
};
</script>

<style scoped>
.modal-outer-enter-active,
.modal-outer-leave-active {
  transition: opacity 0.5s cubic-bezier(0.52, 0.02, 0.19, 1.02);
}

.modal-outer-enter-from,
.modal-outer-leave-to {
  opacity: 0;
}
.modal-inner-enter-active {
  transition: all 0.5s cubic-bezier(0.52, 0.02, 0.19, 1.02) 0.15s;
}
.modal-inner-leave-acitve {
  transition: all 0.5s cubic-bezier(0.52, 0.02, 0.19, 1.02);
}
.modal-innner-enter-from {
  opacity: 0;
  transform: scale(0.8);
}
.modal-inner-enter-leave-to {
  transform: scale(0.8);
}
</style>
