<template>
  <div
    class="flex rounded-3xl p-1 items-center my-3 hover:bg-a-profile-l-bg profile-h"
    :class="{
      'bg-a-profile-l-bg route-active': active,
    }"
  >
    <div
      class="p-2 rounded-full circle mr-2"
      :class="{
        'bg-a-link route-active': active,
        'bg-a-profile-i-bg': !active,
      }"
    >
      <slot name="icon" />
    </div>
    <p
      class="font-gilroy text-lg font-bold"
      :class="{ 'text-a-link ': active, 'text-a-black-2': !active }"
    >
      <slot name="text" />
    </p>
  </div>
</template>

<script>
export default {
  name: "profile-link",
  props: ["active"],
};
</script>

<style scoped>
.profile-h,
.profile-h * {
  transition: all 0.3s ease-in-out;
}
.profile-h:hover > p {
  color: #4a60d1;
}
.profile-h:hover > .circle {
  background: #4a60d1;
}
.profile-h:hover svg,
.route-active {
  fill: #4a60d1;
}
</style>
