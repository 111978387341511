import { createStore } from "vuex";
import axios from "axios";
import filterPar from "./filter/filterPar";
import judges from "./judges/judges";
import students from "./judges/students";

export default createStore({
  state: {
    user: {},
    isAuth: false,
    judges: [],
    judgesCount: [],
    chartData: [],
    profile: {},
    image: "",
    profile_main: {},
  },
  getters: {
    profileImg(state) {
      return state.image;
    },
    profile(state) {
      return state.profile;
    },
    judgesCount(state) {
      return state.judgesCount;
    },
    chartData(state) {
      return state.chartData;
    },
    isAuth(state) {
      state.isAuth = localStorage.getItem("token") !== null;
      return state.isAuth;
    },
    User(state) {
      return state.user;
    },
    Name(state) {
      if (state.user.user_type === "VIEWER") return state.user.short_user_name;
      else return state.user.short_user_name;
    },
    profileMain(state) {
      return state.profile_main;
    },
    Token(state) {
      return localStorage.getItem("token");
    },
  },
  mutations: {
    updateProfileImage(state, data) {
      state.image = data.photo;
    },
    updateProfile(state, data) {
      state.profile = data;
    },
    updateProfileMain(state, data) {
      state.profile_main = data;
    },
    updateChartData(state, data) {
      state.chartData = [
        data.all_rating.score,
        data.administrative.score,
        data.administrative_district.score,
        data.administrative_region.score,
        data.civil.score,
        data.criminal.score,
        data.economic.score,
        data.jurisdiction.score,
      ];
    },
    updateUser(state, user) {
      state.user = user;
    },
    updateIsAuth(state, isAuth) {
      state.isAuth = isAuth;
    },
    updateJudgesCount(state, data) {
      let d = [];
      data?.forEach((element) => {
        let obj = [];
        obj.push(element.regions.name_uz);
        obj.push(element.all_rating.score);
        d.push(obj);
      });
      let judgesMap = [];
      data?.forEach((element) => {
        let obj = {};
        if (element.regions.id === "00s0eed0000region000001") obj.id = "UZ.QR";
        else if (element.regions.id === "00s0eed0000region000002")
          obj.id = "UZ.AN";
        else if (element.regions.id === "00s0eed0000region000003")
          obj.id = "UZ.BU";
        else if (element.regions.id === "00s0eed0000region000004")
          obj.id = "UZ.JI";
        else if (element.regions.id === "00s0eed0000region000005")
          obj.id = "UZ.QA";
        else if (element.regions.id === "00s0eed0000region000006")
          obj.id = "UZ.NW";
        else if (element.regions.id === "00s0eed0000region000007")
          obj.id = "UZ.NG";
        else if (element.regions.id === "00s0eed0000region000008")
          obj.id = "UZ.SA";
        else if (element.regions.id === "00s0eed0000region000009")
          obj.id = "UZ.SU";
        else if (element.regions.id === "00s0eed0000region000010")
          obj.id = "UZ.SI";
        else if (element.regions.id === "00s0eed0000region000011")
          obj.id = "UZ.TK";
        else if (element.regions.id === "00s0eed0000region000012")
          obj.id = "UZ.FA";
        else if (element.regions.id === "00s0eed0000region000013")
          obj.id = "UZ.KH";
        else if (element.regions.id === "00s0eed0000region000014")
          obj.id = "UZ.TA";

        obj.value = element.all_rating.score;
        judgesMap.push(obj);
      });
      judgesMap.push({
        id: "UZ.JI",
        value: 1,
      });
      state.judgesCount = judgesMap;
    },
  },
  actions: {
    loadPDF(context, id) {
      axios
        .post("https://e-justice.ai-softdev.com/api/v1/sud/main/", {
          url: "http://185.203.238.157:7889/api/profile/download/pdf",
          access_token: localStorage.getItem("token"),
          data: {
            alphabet: "CYRILLIC",
            profile_id: id,
          },
        })
        .then((response) => {
          const linkSource = `data:application/pdf;base64,${response.data.result}`;
          const downloadLink = document.createElement("a");
          const fileName = "profile.pdf";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        });
    },
    loadChartData(context, par) {
      axios
        .post("https://e-justice.ai-softdev.com/api/v1/sud/main/", {
          url: "http://185.203.238.157:7889/api/report/all-statistics-first-table",
          access_token: localStorage.getItem("token"),
          data: {},
        })
        .then((response) => {
          let obj = response.data.result?.filter((el) => {
            return el.regions.name_uz === par;
          });
          if (obj?.length > 0) {
            obj = obj[0];
            context.commit("updateChartData", obj);
          }
        });
    },
    loadJudgesCount(context) {
      axios
        .post("https://e-justice.ai-softdev.com/api/v1/sud/main/", {
          url: "http://185.203.238.157:7889/api/report/all-statistics-second-table",
          access_token: localStorage.getItem("token"),
          data: {},
        })
        .then((response) => {
          context.commit("updateJudgesCount", response.data.result);
        });
    },

    async Auth(context, user) {
      // https://e-justice.ai-softdev.com/auth/token/login
      // https://e-justice.ai-softdev.com/api/v1/sud/auth/
      //asdasd asd
      axios
        .post("https://e-justice.ai-softdev.com/auth/token/login", {
          username: user.username,
          password: user.password,
        })

        .then((response) => {
          localStorage.setItem("auth_token", response.data.auth_token);
          let config = {
            headers: {
              Authorization: "Token " + response.data.auth_token,
            },
          };

          axios
            .get("https://e-justice.ai-softdev.com/api/v1/authtoken/", config)
            .then((res) => {
              localStorage.setItem("token", res.data.access_token);
              context.commit("updateIsAuth", true);
              context.commit("updateUser", res.data.user);
            });

          // context.dispatch("login");
        })
        .catch((e) => {
          alert("login or password wrong");
          localStorage.clear();
        });
    },
    async CurrentUser(context) {
      if (Object.keys(context.getters.User).length === 0) {
        axios
          .post("https://e-justice.ai-softdev.com/api/v1/sud/main/", {
            url: "http://185.203.238.157:7889/api/auth/currentUser",
            access_token: this.getters.Token,
            data: {},
          })
          .then((response) => {
            context.commit("updateUser", response.data.result);
            context.commit("updateIsAuth", true);
          })
          .catch((s) => {
            localStorage.clear();

            context.commit("updateIsAuth", false);
          });
      }
    },
    checkAuth(context) {
      context.commit("updateIsAuth", localStorage.getItem("token") !== null);
    },
    loadMainInfo(context, id) {
      axios
        .post("https://e-justice.ai-softdev.com/api/v1/sud/main/", {
          url: "http://185.203.238.157:7889/api/profile/select/by-id",
          access_token: localStorage.getItem("token"),
          data: {
            id: id,
          },
        })
        .then((response) => {
          context.commit("updateProfileMain", response.data.result);
        })
        .catch((err) => {
          localStorage.clear();
        });
    },
    loadUser(context, id) {
      axios
        .post("https://e-justice.ai-softdev.com/api/v1/sud/main/", {
          url: "http://185.203.238.157:7889/api/profile-rating/select/profile-score-sheet",
          access_token: localStorage.getItem("token"),
          data: {
            profile_id: id,
          },
        })
        .then((response) => {
          context.commit("updateProfile", response.data.result);
        })
        .catch((err) => {
          localStorage.clear();
        });
    },
    loadUserImage(context, id) {
      axios
        .post("https://e-justice.ai-softdev.com/api/v1/sud/main/", {
          url: "http://185.203.238.157:7889/api/profile-avatars/select/main-photo",
          access_token: localStorage.getItem("token"),
          data: {
            profile_id: id,
          },
        })
        .then((response) => {
          context.commit("updateProfileImage", response.data.result);
        })
        .catch((err) => {
          localStorage.clear();
        });
    },
  },
  modules: {
    f: filterPar,
    judges: judges,
    students: students,
  },
});
