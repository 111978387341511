<template>
  <div class="flex justify-between">
    <p class="text-a-table-h font-slalom w-10/12">{{ text }}</p>
    <div
      v-if="parseInt(type) === 2"
      class="bg-a-green flex justify-center text-white font-bold font-slalom px-5 py-1 w-2/12"
    >
      {{ left }}
    </div>
    <div v-else class="flex justify-center bg-a-table-h-bg px-5 py-1 w-2/12">
      <span class="text-a-table-h-l font-slalom font-bold">{{ left }}/ </span>
      <span class="text-a-table-h font-slalom font-bold">{{ right }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ["text", "left", "right", "type"],
};
</script>

<style lang="scss" scoped></style>
