<template>
  <div class="my-2">
    <label :for="name" class="w-full block text-sm font-bold">{{
      label
    }}</label>
    <input
      :type="type"
      :id="name"
      :placeholder="placeholder"
      :value="modelValue"
      @change="change"
      @input="$emit('update:modelValue', $event.target.value)"
      class="w-full bg-l-input rounded-xl p-4 focus:outline-none text-l-i-text text-base"
    />
  </div>
</template>

<script>
export default {
  //   emits: ["update:value"],
  props: ["type", "label", "name", "placeholder", "modelValue", "change"],
};
</script>

<style lang="scss" scoped></style>
