<template>
  <div class="flex justify-between items-center flex-wrap">
    <v-filter-vue
      :text_top="$t('personal_info')"
      :text_bottom="$t('sud_info')"
      :clear="clear"
      :commit="commit"
      :filter="filter"
      :temp="temp"
      class="my-inputs"
    />
  </div>
  <div class="">
    <v-line-vue class="mt-24 w-full" />
    <div class="flex items-center justify-end w-full">
      <div class="flex">
        <div class="flex items-center justify-between my-inputs mr-2">
          <span class="font-gilroy">{{ $t("items_count") }}:</span>
          <select
            class="w-max font-gilroy ml-3 mx-4 text-center px-1 py-1 justify-center border rounded-3xl border-a-link-b"
            name=""
            id=""
            v-model="filter.limit"
          >
            <option :value="item.key" v-for="item in limits">
              {{ item.value }}
            </option>
          </select>
          <span>{{ paginateDescription }}</span>
        </div>
        <paginate
          v-model="filter.page"
          :next-text="'>'"
          :prev-text="'<'"
          :page-range="1"
          :margin-pages="1"
          :page-count="pageCount"
          :click-handler="changePage"
          :container-class="'flex items-center'"
          :page-class="'mx-2 font-gilroy rounded border cursor-pointer border-a-border text-a-border py-1 px-3'"
        />
      </div>
    </div>
    <div
      class="flex flex-wrap justify-between w-full border border-a-border mt-5 p-5"
    >
      <div class="flex items-center overflow-x-auto maxmd:max-w-7xl w-full">
        <p
          class="font-gilroy border text-center w-1/12 maxmd:w-20 h-full text-black font-bold border-a-border border-l-0 border-t-0 px-10 py-2.5 justify-between items-center flex"
        >
          №
        </p>
        <p
          @click="sort('full_name')"
          class="font-gilroy font-bold cursor-pointer justify-center text-center items-center w-2/12 maxmd:w-72 flex text-xs text-black p-2.5 border border-a-border border-t-0 border-l-0 h-full"
        >
          {{ $t("FIO") }}
        </p>
        <p
          class="font-gilroy font-bold justify-center text-center items-center w-2/12 flex maxmd:w-96 text-xs text-black p-2.5 border border-a-border border-l-0 border-t-0 h-full"
        >
          {{ $t("work") }}
        </p>
        <p
          @click="sort('nationality')"
          class="font-gilroy font-bold justify-center cursor-pointer text-center items-center w-1/12 flex maxmd:w-24 text-xs text-black p-2.5 border border-a-border border-l-0 border-t-0 h-full"
        >
          {{ $t("nationality") }}
        </p>
        <p
          class="font-gilroy font-bold justify-center text-center items-center text-xs maxmd:w-36 w-2/12 text-black flex flex-wrap p-2.5 border border-a-border border-l-0 border-t-0 h-full"
        >
          {{ $t("work_period") }}
        </p>
        <p
          @click="sort('date_of_birth')"
          class="font-gilroy font-bold justify-center cursor-pointer text-center items-center maxmd:w-44 w-2/12 text-xs text-black flex flex-wrap p-2.5 border border-a-border border-l-0 border-t-0 h-full"
        >
          {{ $t("date_birth") }}
        </p>
        <p
          @click="sort('work_experience_in_years')"
          class="font-gilroy font-bold justify-center cursor-pointer text-xs maxmd:w-20 items-center w-1/12 flex text-black p-2.5 text-center border border-a-border border-l-0 border-t-0 h-full"
        >
          {{ $t("staj") }}
        </p>
        <p
          @click="sort('profile_rating')"
          class="font-gilroy font-bold justify-center cursor-pointer text-xs maxmd:w-48 items-center flex w-1/12 text-black p-2.5 text-center border border-a-border border-l-0 border-t-0 h-full border-r-0"
        >
          {{ $t("rating") }}
        </p>
      </div>
      <!-- <transition-group name="list"> -->
      <transition-group name="judges">
        <v-sudya-vue
          :key="index"
          :item="item"
          :number="(filter.page - 1) * filter.limit + index + 1"
          v-for="(item, index) in list"
        />
      </transition-group>
      <!-- </transition-group> -->
    </div>
  </div>
</template>
<script>
import vLineVue from "@/components/Admin/v-line.vue";
import vInputVue from "@/components/Admin/v-input.vue";
import vSelectVue from "@/components/Admin/v-select.vue";
import vFilterVue from "@/components/Admin/v-filter.vue";
import vSudyaVue from "@/components/Admin/v-sudya.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Search",
  computed: {
    ...mapGetters(["getJudges"]),

    pageCount() {
      return Math.ceil(parseInt(this.getJudges[0]?.total) / this.filter.limit);
    },
    paginateDescription() {
      let start = (this.filter.page - 1) * this.filter.limit;
      let end = start + this.filter.limit;
      let all = this.getJudges[0]?.total;
      if (all === undefined) all = 0;
      if (all <= end) end = all;
      return `${start}-${end} ${this.$t("from")} ${all}`;
    },
  },
  methods: {
    sort(par) {
      if (this.par === par) this.asc = !this.asc;
      else this.asc = true;
      this.par = par;
      if (par === "date_of_birth") {
        this.list = this.getJudges.sort((first, second) => {
          console.log(first);
          if (this.asc) return new Date(second[par]) - new Date(first[par]);
          return new Date(first[par]) - new Date(second[par]);
        });
      } else if (
        par === "work_experience_in_years" ||
        par === "profile_rating"
      ) {
        this.list = this.getJudges.sort((first, second) => {
          if (this.asc) return parseFloat(second[par]) - parseFloat(first[par]);
          return parseFloat(first[par]) - parseFloat(second[par]);
        });
      } else {
        this.list = this.getJudges.sort((first, second) => {
          if (this.asc) return first[par]?.localeCompare(second[par]);
          return -1 * first[par]?.localeCompare(second[par]);
        });
      }
    },
    clear() {
      this.filter = {
        limit: 50,
        page: 1,
      };
    },
    commit() {
      this.loadJudges(this.filter);
    },
    ...mapActions(["loadJudges"]),
    changePage(data) {
      this.loadJudges(this.filter);
    },
  },
  watch: {
    getJudges(val) {
      this.list = val;
    },
  },
  mounted() {
    this.loadJudges(this.filter);
    document.querySelectorAll(".my-inputs select").forEach((el) => {
      el.onchange = (event) => {
        this.loadJudges(this.filter);
      };
    });
    document.querySelectorAll(".my-inputs input").forEach((el) => {
      el.onchange = (event) => {
        this.loadJudges(this.filter);
      };
    });
  },
  data() {
    return {
      list: [],
      asc: true,
      par: "rating",
      limits: [
        {
          key: 50,
          value: 50,
        },
        {
          key: 100,
          value: 100,
        },
        {
          key: 200,
          value: 200,
        },
        {
          key: 500,
          value: 500,
        },
        {
          key: 1000,
          value: 1000,
        },
      ],
      filter: {
        limit: 50,
        page: 1,
        // name: "",
        // region: "",
        // sudTuri: " ",
        // sudIxtisosligi: "",
        // searchText: "",
        // lavozim: "",
        // kimTomondan: "",
        // yashashJoyi: "",
        // millati: "",
        // tomomlaganSana: "",
        // tajribaDan: "",
        // tajribaGacha: "",
        // hudud: "",
        // ishUrni: "",
      },
      temp: [
        {
          key: "key 1",
          value: "value 1",
        },
        {
          key: "key 2",
          value: "value 2",
        },
        {
          key: "key 3",
          value: "value 3",
        },
      ],
    };
  },
  components: {
    vLineVue,
    vInputVue,
    vSelectVue,
    vFilterVue,
    vSudyaVue,
  },
};
</script>
<style>
.active {
  color: #2e6df3 !important;
  border-color: #2e6df3 !important;
}
.page-item {
  padding: 4px 12px;
  border: 1px solid #b3b6bc;
  border-radius: 4px;
  cursor: pointer;
}
.judges-move {
  transition: all 0.3s ease;
}
</style>
