import axios from "axios";

export default {
  state: {
    judges: [],
    sobikJudges: [],
    judgesRating: [],
    work: [],
  },
  getters: {
    work(state) {
      return state.work;
    },
    getJudgesRating(state) {
      return state.judgesRating;
    },
    getJudges(state) {
      return state.judges;
    },
    getSobikJudges(state) {
      return state.sobikJudges;
    },
  },
  mutations: {
    updateWork(state, data) {
      state.work = data;
    },
    updateJudgesRating(state, data) {
      state.judgesRating = data;
    },
    updateJudges(state, data) {
      state.judges = data;
    },
    updateSobikJudges(state, data) {
      state.sobikJudges = data;
    },
  },
  actions: {
    loadWork(context, id) {
      axios
        .post("https://e-justice.ai-softdev.com/api/v1/sud/main/", {
          url: "http://185.203.238.157:7889/api/staff-history/select/list",
          access_token: localStorage.getItem("token"),
          data: {
            profile_id: id,
          },
        })
        .then((response) => {
          context.commit("updateWork", response.data.result);
        });
    },
    loadJudgesRating(context) {
      // axios.post('https://e-justice.ai-softdev.com/api/v1/sud/main/',{
      //     url:'http://185.203.238.157:7889/api/report/all-statistics-second-table',
      //     access_token:localStorage.getItem('token'),
      //     data:{
      //     }
      // }).then(response=>{
      //     context.commit('updateJudgesRating', response.data.result)
      // })
    },
    loadSobik(context, filter) {
      let f = {};
      if (filter["region"] !== undefined) f.region_ids = [filter.region];
      if (filter["sudIxtisosligi"] !== undefined)
        f.court_types = [filter.sudIxtisosligi];
      if (filter["sudTuri"] !== undefined)
        f.court_specialities = [filter.sudTuri];
      if (filter["kimTomondan"] !== undefined)
        f.staff_options = [filter.kimTomondan];
      if (filter["yashashJoyi"] !== undefined)
        f.permanent_region_ids = [filter.yashashJoyi];
      if (filter["millati"] !== undefined) f.nationality = filter.millati;
      if (filter["tomomlaganSana"] !== undefined)
        f.finish_date = filter.tomomlaganSana;
      if (filter["ishUrni"] !== undefined) f.start_date = filter.ishUrni;
      if (filter["tajribaDan"] !== undefined)
        f.start_profile_rating = parseInt(filter.tajribaDan);
      if (filter["tajribaGacha"] !== undefined)
        f.finish_profile_rating = parseInt(filter.tajribaGacha);
      if (filter["hudud"] !== undefined) f.region_ids = [filter.hudud];
      if (filter["searchText"] !== undefined) f.searchText = filter.searchText;
      if (filter["name"] !== undefined) f.full_name = filter.name;
      f.page = parseInt(filter.page);
      f.limit = parseInt(filter.limit);
      axios
        .post("https://e-justice.ai-softdev.com/api/v1/sud/main/", {
          url: "http://185.203.238.157:7889/api/profile/select/ex-profiles-by-rating",
          access_token: localStorage.getItem("token"),
          data: f,
        })
        .then((response) => {
          console.log(response.data.result);
          context.commit("updateSobikJudges", response.data.result);
        });
    },
    loadJudges(context, filter) {
      let f = {};
      if (filter["region"] !== undefined) f.region_ids = [filter.region];
      if (filter["sudIxtisosligi"] !== undefined)
        f.court_types = [filter.sudIxtisosligi];
      if (filter["sudTuri"] !== undefined)
        f.court_specialities = [filter.sudTuri];
      if (filter["kimTomondan"] !== undefined)
        f.staff_options = [filter.kimTomondan];
      if (filter["yashashJoyi"] !== undefined)
        f.permanent_region_ids = [filter.yashashJoyi];
      if (filter["millati"] !== undefined) f.nationality = filter.millati;
      if (filter["tomomlaganSana"] !== undefined)
        f.finish_date = filter.tomomlaganSana;
      if (filter["ishUrni"] !== undefined) f.start_date = filter.ishUrni;
      if (filter["tajribaDan"] !== undefined)
        f.start_profile_rating = parseInt(filter.tajribaDan);
      if (filter["tajribaGacha"] !== undefined)
        f.finish_profile_rating = parseInt(filter.tajribaGacha);
      if (filter["hudud"] !== undefined) f.region_ids = [filter.hudud];
      if (filter["searchText"] !== undefined) f.searchText = filter.searchText;
      if (filter["name"] !== undefined) f.full_name = filter.name;
      f.page = parseInt(filter.page);
      f.limit = parseInt(filter.limit);
      axios
        .post("https://e-justice.ai-softdev.com/api/v1/sud/main/", {
          url: "http://185.203.238.157:7889/api/profile/select/working-profiles-by-rating",
          access_token: localStorage.getItem("token"),
          data: f,
        })
        .then((response) => {
          context.commit("updateJudges", response.data.result);
        });
    },
  },
};
