<template>
  <form
    @submit.prevent="commit"
    class="flex flex-wrap justify-between w-full gap-y-3"
  >
    <p class="font-bold text-2xl text-a-text font-gilroy">
      {{ text_top }}
    </p>
    <v-line-vue />
    <div class="flex flex-wrap w-full gap-y-3">
      <v-input-vue
        class="md:w-4/12 px-2 maxmd:w-6/12"
        :label="$t('FIO')"
        :placeholder="$t('FIO')"
        v-model="filter.name"
        type="text"
      />
      <v-select-vue
        class="md:w-4/12 px-2 maxmd:w-6/12"
        :items="getKimTomonidan"
        :text="$t('from_sud')"
        v-model="filter.kimTomondan"
      />
      <v-select-vue
        class="md:w-4/12 px-2 maxmd:w-6/12"
        :items="getRegions"
        :text="$t('live_place')"
        v-model="filter.yashashJoyi"
      />
      <v-select-vue
        class="md:w-4/12 px-2 maxmd:w-6/12"
        :items="getNationality"
        :text="$t('nationality')"
        v-model="filter.millati"
      />
      <v-input-vue
        class="md:w-4/12 px-2 maxmd:w-6/12"
        :label="$t('end')"
        v-model="filter.tomomlaganSana"
        :placeholder="$t('end')"
        type="date"
      />
    </div>
    <p class="font-bold text-2xl text-a-text font-gilroy mt-14">
      {{ text_bottom }}
    </p>
    <v-line-vue />
    <div class="flex flex-wrap w-full gap-y-3">
      <v-select-vue
        class="md:w-4/12 px-2 maxmd:w-6/12"
        :items="getRegions"
        :text="$t('region')"
        v-model="filter.region"
      />
      <v-select-vue
        class="md:w-4/12 px-2 maxmd:w-6/12"
        :items="getSudTuri"
        :text="$t('sud_type')"
        v-model="filter.sudTuri"
      />
      <v-select-vue
        class="md:w-4/12 px-2 maxmd:w-6/12"
        :items="getSudIxtisosligi"
        :text="$t('sud_ixtisosligi')"
        v-model="filter.sudIxtisosligi"
      />
      <v-input-vue
        class="md:w-4/12 px-2 maxmd:w-6/12"
        :label="$t('sud_name')"
        v-model="filter.searchText"
        :placeholder="$t('sud_name')"
        type="text"
      />

      <v-input-vue
        class="md:w-4/12 px-2 maxmd:w-6/12"
        :label="$t('rating_start')"
        v-model="filter.tajribaDan"
        :placeholder="$t('rating_start')"
        type="text"
      />
      <v-input-vue
        class="md:w-4/12 px-2 maxmd:w-6/12"
        :label="$t('rating_finish')"
        v-model="filter.tajribaGacha"
        :placeholder="$t('rating_finish')"
        type="text"
      />

      <v-select-vue
        class="md:w-4/12 px-2 maxmd:w-6/12"
        :items="getPositions"
        :text="$t('profession')"
        v-model="filter.lavozim"
      />

      <v-select-vue
        class="md:w-4/12 px-2 maxmd:w-6/12"
        :items="getRegions"
        :text="$t('place')"
        v-model="filter.hudud"
      />
      <v-input-vue
        class="md:w-4/12 px-2 maxmd:w-6/12"
        :label="$t('finish_work')"
        :placeholder="$t('finish_work')"
        type="date"
        v-model="filter.ishUrni"
      />
    </div>
    <v-line-vue />
    <div class="w-full px-2 flex justify-end flex-wrap items-end">
      <button
        @click="commit"
        class="max-w-btn w-full bg-a-f-btn py-4 mx-2 rounded-3xl text-lg text-white h-16"
      >
        {{ $t("search_text") }}
      </button>
      <button
        @click="clear"
        class="max-w-btn w-full flex items-center mx-2 rounded-3xl border border-a-btn-b h-16 py-4 justify-center"
      >
        <img src="@/assets/refresh.svg" alt="" />
        <span class="text-lg text-a-btn-b ml-2"> {{ $t("clear") }} </span>
      </button>
    </div>
  </form>
</template>

<script>
import vInputVue from "./v-input.vue";
import vSelectVue from "./v-select.vue";
import vLineVue from "./v-line.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["filter", "temp", "clear", "commit", "text_top", "text_bottom"],
  components: {
    vInputVue,
    vSelectVue,
    vLineVue,
  },
  computed: mapGetters([
    "getRegions",
    "getPositions",
    "getSudIxtisosligi",
    "getSudTuri",
    "getKimTomonidan",
    "getNationality",
  ]),
  methods: {
    ...mapActions(["loadRegions", "loadPositions"]),
  },
  mounted() {
    this.loadPositions();
    this.loadRegions();
  },
};
</script>

<style lang="scss" scoped></style>
