<template>
  <div
    :class="{ 'bg-a-link-b route-active': active }"
    class="my-link font-gilroy hover:bg-a-link-b mt-1 flex items-center py-3 px-4 space-x-2 rounded-3xl duration-200"
  >
    <slot />
    <span class="text-white">{{ item }}</span>
  </div>
</template>

<script>
export default {
  props: ["item", "active"],
};
</script>

<style>
.my-link {
  transition: all 0.2s ease-in-out;
}
.route-active svg path {
  fill: #fff;
}
.my-link:hover svg path {
  fill: #fff;
}
</style>
