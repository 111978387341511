<template>
  <div class="">
    <div class="shadow-l-shadow rounded-3xl mb-5">
      <div
        class="bg-a-link-b items-center flex justify-between py-5 px-7 rounded-tl-3xl rounded-tr-3xl"
      >
        <div class="">
          <p class="text-xl text-white font-gilroy font-bold">
            Судьянинг фаолияти самарадорлигини электрон рейтинг бахолаш
          </p>
          <p class="text-sm text-white font-gilroy">Основные критерии</p>
        </div>
        <div class=""></div>
      </div>
      <div class="">
        <v-profile-info-item-vue>
          <template v-slot:left>
            <p class="font-gilroy text-lg text-a-black-2">
              Суд қарорларининг сифати:
            </p></template
          >
          <template v-slot:right
            ><p class="font-gilroy text-lg text-a-black-2">
              {{ profile.profile_rating?.rating_quality }}
            </p></template
          >
        </v-profile-info-item-vue>
        <v-profile-info-item-vue>
          <template v-slot:left>
            <p class="font-gilroy text-lg text-a-black-2">
              Судьянинг одоби:
            </p></template
          >
          <template v-slot:right
            ><p class="font-gilroy text-lg text-a-black-2">
              {{ profile.profile_rating?.behaviour }}
            </p></template
          >
        </v-profile-info-item-vue>

        <v-profile-info-item-vue>
          <template v-slot:left>
            <p class="font-gilroy text-lg text-a-black-2">
              Судьянинг масъулияти:
            </p></template
          >
          <template v-slot:right
            ><p class="font-gilroy text-lg text-a-black-2">
              {{ profile.profile_rating?.responsibility }}
            </p></template
          >
        </v-profile-info-item-vue>

        <v-profile-info-item-vue>
          <template v-slot:left>
            <p class="font-gilroy text-lg text-a-black-2">
              Тарғибот ишлари:
            </p></template
          >
          <template v-slot:right
            ><p class="font-gilroy text-lg text-a-black-2">
              {{ profile.profile_rating?.propaganda }}
            </p></template
          >
        </v-profile-info-item-vue>

        <v-profile-info-item-vue>
          <template v-slot:left>
            <p class="font-gilroy text-lg text-a-black-2">
              Чет тили:
            </p></template
          >
          <template v-slot:right
            ><p class="font-gilroy text-lg text-a-black-2">
              {{ profile.profile_rating?.language }}
            </p></template
          >
        </v-profile-info-item-vue>

        <v-profile-info-item-vue>
          <template v-slot:left>
            <p class="font-gilroy text-lg text-a-black-2">
              Қўшимча мезонлар:
            </p></template
          >
          <template v-slot:right
            ><p class="font-gilroy text-lg text-a-black-2">
              {{ profile.profile_rating?.bonus }}
            </p></template
          >
        </v-profile-info-item-vue>
      </div>
    </div>
    <div class="shadow-l-shadow rounded-3xl bg-white py-4 px-1">
      <p class="mx-12 text-a-black-2 font-slalom text-xl">Рейтинг судьи</p>
      <p class="mt-1 mx-12 text-a-black-2 font-slalom text-2xl font-bold">
        {{ profile.profile_rating?.total }}
      </p>

      <div id="rating_chart" class="h-80"></div>
    </div>
    <div
      class="rounded-xl text-a-profile-rating-b font-slalom text-lg bg-a-link py-2 px-4 bg-opacity-30 border-l-8 my-4 border-l-a-link"
      id="ratingInfo"
    ></div>
    <div class="flex flex-col gap-10">
      <div class="">
        <v-table-h-vue
          text="Cуд карорларининг сифати:"
          :left="profile.profile_rating?.rating_quality"
          :right="profile.profile_rating?.rating_quality"
        />
        <v-table-r-vue
          v-for="item in profile.quality?.appeal.rejected.subCategory"
          :text="item.name_uz"
          :left="item.count"
          :right="`-${item.point}`"
          type="-1"
        />
        <v-table-r-vue
          text="Жами"
          :left="profile.quality?.appeal.rejected.count"
          :right="profile.quality?.appeal.rejected.score"
          type="-1"
        />
      </div>
      <div class="">
        <v-table-h-vue
          text="Судьянинг одоби ва маъсулияти:"
          left="45"
          right="45"
        />
        <v-table-r-vue text="Жами" left="" right="" type="-1" />
      </div>
      <div class="">
        <v-table-h-vue text="Таргибот ишлари" left="10" :right="sumTargibot" />
        <v-table-r-vue
          v-for="item in targibotIshlari"
          :text="item.text"
          :left="item.left"
          :right="item.right"
          type="1"
        />
      </div>
      <div class="">
        <v-table-h-vue
          text="Чет тилларини билиши:"
          left="5"
          :right="sumLanguage"
        />
        <v-table-r-vue
          v-for="item in language"
          :text="item.text"
          :left="item.left"
          :right="item.right"
          type="0"
        />
      </div>
      <div class="">
        <v-table-h-vue
          text="Кушимча баллар:"
          :left="sumBonus"
          right="0"
          type="2"
        />
        <v-table-r-vue
          v-for="item in bonus"
          :text="item.text"
          :left="item.left"
          :right="item.right"
          type="1"
        />
      </div>
    </div>
  </div>
</template>

<script>
import vTableHVue from "@/components/Admin/v-table-h.vue";
import vTableRVue from "@/components/Admin/v-table-r.vue";
import vProfileInfoItemVue from "@/components/Admin/v-profile-info-item.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    vProfileInfoItemVue,
    vTableHVue,
    vTableRVue,
  },
  computed: {
    ...mapGetters(["profile"]),
    sumTargibot() {
      if (this.targibotIshlari.length > 0)
        return this.targibotIshlari[this.targibotIshlari.length - 1].right;
      return 0;
    },
    targibotIshlari() {
      let arr = [];
      if (Object.keys(this.profile).length !== 0) {
        let sum = 0;
        let sum2 = 0;

        this.profile.propaganda.forEach((el) => {
          let temp = {};
          temp.left = Number(el.count).toFixed(1);
          sum += Number(el.count);
          sum2 += Number(el.score);
          temp.right = "+" + Number(el.score).toFixed(1);
          if (el.type == "INTERVIEW") temp.text = "Телевидениеда интервью";
          if (el.type == "MEDIA")
            temp.text = "Оммавий ахборот воситаларида макола";
          if (el.type == "PROPAGANDA") temp.text = "Огзаки таргибот";
          if (el.type == "RULING") temp.text = "Хусусий ажрим";
          arr.push(temp);
        });
        sum = Number(sum).toFixed(1);
        sum2 = Number(sum2).toFixed(1);
        arr.push({ text: "Жами", left: sum, right: sum2 });
      }
      return arr;
    },
    sumLanguage() {
      if (this.language.length > 0)
        return this.language[this.language.length - 1].right;
      return 0;
    },

    language() {
      let arr = [];
      if (Object.keys(this.profile).length !== 0) {
        let sum = 0;
        let sum2 = 0;
        this.profile.language.forEach((el) => {
          let temp = {};
          temp.left = el.count;
          sum += Number(el.count).toFixed(1);
          sum2 += Number(el.score).toFixed(1);
          sum = Number(sum).toFixed(1);
          sum2 = Number(sum2).toFixed(1);

          temp.right = Number(el.score).toFixed(1);
          temp.text = el.languages.name_uz;
          arr.push(temp);
        });
        arr.push({ text: "Жами", left: sum, right: sum2 });
      }
      return arr;
    },

    sumBonus() {
      if (this.bonus.length > 0) return this.bonus[this.bonus.length - 1].right;
      return 0;
    },

    bonus() {
      let arr = [];
      var sum = 0;
      var sum2 = 0;
      if (Object.keys(this.profile).length !== 0) {
        this.profile.bonus.forEach((el) => {
          let temp = {};
          temp.text = el.bonus.name_uz;
          temp.left = el.count;
          sum += Number(el.count);
          sum2 += el.bonus.point;
          sum = Number(sum);
          sum2 = Number(sum2);

          temp.right = "+" + Number(el.score).toFixed(1);
          arr.push(temp);
        });
        arr.push({ text: "Жами", left: "+" + sum, right: sum2 });
      }
      return arr;
    },
  },
  data() {
    return {
      drawed: false,
    };
  },
  methods: {
    ...mapActions(["loadUser"]),
    drawRating() {
      if (Object.keys(this.profile).length !== 0) {
        console.log("this.profile");
        console.log(this.profile);
        this.drawed = true;
        var chart = anychart.line();

        // set chart padding
        chart.padding([10, 20, 5, 20]);

        // turn on chart animation
        chart.animation(true);

        // turn on the crosshair

        // set chart title text settings

        // set y axis title

        // create logarithmic scale
        var logScale = anychart.scales.linear();
        logScale.minimum(0).maximum(150);

        // set scale for the chart, this scale will be used in all scale dependent entries such axes, grids, etc
        chart.yScale(logScale);

        // create data set on our data,also we can pud data directly to series
        let arr = this.profile.profile_rating_history;
        let temp = [];
        arr.forEach((el) => {
          temp.push([el.created_at, el.rating]);
        });
        temp = temp.reverse();
        var dataSet = anychart.data.set(temp);

        // map data for the first series,take value from first column of data set
        var firstSeriesData = dataSet.mapAs({ x: 0, value: 1 });

        // map data for the second series,take value from second column of data set

        // map data for the third series, take x from the zero column and value from the third column of data set

        // temp variable to store series instance
        var series;

        // setup first series
        series = chart.line(firstSeriesData);
        // enable series data labels
        series.labels().enabled(true).anchor("left-bottom").padding(5);
        // enable series markers
        series.markers(true);
        series.normal().stroke("#4A60D1");
        series.selected().fill("#4A60D1");
        series.hovered().fill("#4A60D1");
        // setup second series
        series.name("Рейтинг");
        // enable series data labels

        // turn the legend on
        var background = chart.tooltip().background();
        background.fill("#1A4A90 0.8");
        background.stroke("#1A4A90");
        background.cornerType("cut");
        background.corners(5, 5, 5, 5);
        // set container for the chart and define padding
        chart.container("rating_chart");
        // initiate chart drawing
        chart.draw();
      }
    },
    ratingInfo() {
      let str = "";
      if (Object.keys(this.profile).length !== 0) {
        let obj = this.profile.overload_bonus;
        if (obj.criminal_rating_quality_first_count !== 0) {
          str += `Биринчи инстанцияд <strong>${obj.criminal_rating_quality_first_count}</strong> та жиноят ишини куриб тамомлаган.`;
          str += "<br/>";
        }
        if (obj.criminal_quality_appeal_count !== 0) {
          str += `Апелляция инстанциясида <strong>${obj.criminal_quality_appeal_count}</strong> та жиноят иши буйича маъруза килган.`;
          str += "<br/>";
        }
        if (obj.criminal_cassation_count !== 0) {
          str += `Кассация инстанциясида <strong>${obj.criminal_cassation_count}</strong> та жиноят ишини буйича маъруза килган.`;
          str += "<br/>";
        }
        if (obj.offense_rating_quality_first_count !== 0) {
          str += `Биринчи инстанцияда <strong>${obj.offense_rating_quality_first_count}</strong> та маъмурий ҳуқуқбузарлик ишини куриб тамомлаган.`;
          str += "<br/>";
        }
        if (obj.offense_quality_appeal_count !== 0) {
          str += `Апелляция инстанциясида <strong>${obj.offense_quality_appeal_count}</strong> та маъмурий ҳуқуқбузарлик иши буйича маъруза килган.`;
          str += "<br/>";
        }
        if (obj.offense_cassation_count !== 0) {
          str += `Кассация инстанциясида <strong>${obj.offense_cassation_count}</strong> та маъмурий ҳуқуқбузарлик иши буйича маъруза килган.`;
          str += "<br/>";
        }
        if (obj.material_rating_quality_first_count !== 0) {
          str += `Биринчи инстанцияда <strong>${obj.material_rating_quality_first_count}</strong> та материаллар куриб тамомлаган.`;
          str += "<br/>";
        }
        if (obj.material_quality_appeal_count !== 0) {
          str += `Апелляция инстанциясида <strong>${obj.material_quality_appeal_count}</strong> та материаллар буйича маъруза килган.`;
          str += "<br/>";
        }
        if (obj.material_cassation_count !== 0) {
          str += `Кассация инстанциясида <strong>${obj.material_cassation_count}</strong> та материаллар буйича маъруза килган.`;
          str += "<br/>";
        }
        if (obj.citizen_rating_quality_first_count !== 0) {
          str += `Биринчи инстанцияда <strong>${obj.citizen_rating_quality_first_count}</strong> та фуқаролик ишини куриб тамомлаган.`;
          str += "<br/>";
        }
        if (obj.citizen_quality_appeal_count !== 0) {
          str += `Апелляция инстанциясида <strong>${obj.citizen_quality_appeal_count}</strong> та фуқаролик иши буйича маъруза килган.`;
          str += "<br/>";
        }
        if (obj.citizen_cassation_count !== 0) {
          str += `Кассация инстанциясида <strong>${obj.citizen_cassation_count}</strong> та фуқаролик иши буйича маъруза килган.`;
          str += "<br/>";
        }
        if (obj.economic_rating_quality_first_count !== 0) {
          str += `Биринчи инстанцияда <strong>${obj.economic_rating_quality_first_count}</strong> та иқтисодий иш куриб тамомлаган.`;
          str += "<br/>";
        }
        if (obj.economic_quality_appeal_count !== 0) {
          str += `Апелляция инстанциясида <strong>${obj.economic_quality_appeal_count}</strong> та иқтисодий иш буйича маъруза килган.`;
          str += "<br/>";
        }
        if (obj.economic_cassation_count !== 0) {
          str += `Кассация инстанциясида <strong>${obj.economic_cassation_count}</strong> та иш иқтисодий буйича маъруза килган.`;
          str += "<br/>";
        }
        if (obj.administrative_rating_quality_first_count !== 0) {
          str += `Биринчи инстанцияда <strong>${obj.administrative_rating_quality_first_count}</strong> та маъмурий иш куриб тамомлаган.`;
          str += "<br/>";
        }
        if (obj.administrative_quality_appeal_count !== 0) {
          str += `Апелляция инстанциясида <strong>${obj.administrative_quality_appeal_count}</strong> та маъмурий иш буйича маъруза килган.`;
          str += "<br/>";
        }
        if (obj.administrative_cassation_count !== 0) {
          str += `Кассация инстанциясида <strong>${obj.administrative_cassation_count}</strong> та иш маъмурий буйича маъруза килган.`;
          str += "<br/>";
        }
        if (obj.forum_theme_count !== 0) {
          str += `Онлайн форумда <strong>${obj.forum_theme_count}</strong> та мавзу яратган.`;
          str += "<br/>";
        }
        if (obj.forum_post_count !== 0) {
          str += `Онлайн форумда <strong>${obj.forum_post_count}</strong> та хабар қолдирган.`;
          str += "<br/>";
        }
      }

      return str;
    },
  },
  mounted() {
    this.loadUser(this.$router.currentRoute.value.params.id);
    if (this.drawed == false) this.drawRating();
    console.log(this.profile);
  },
  watch: {
    profile() {
      this.drawRating();
      document.querySelector("#ratingInfo").innerHTML = this.ratingInfo();
    },
  },
};
</script>

<style>
#rating_chart > div:nth-child(2) {
  display: none;
}
</style>
