import axios from "axios"

export default {
    state:{
        regions:[],
        sudTuri:[
        {
            key:'administrative',
            value:'Маъмурий'
        },
        {
            key:'supreme',
            value:'Олий'
        },
        {
            key:'jurisdiction',
            value:'Умумюрисдикция суди'
        },
        {
            key:'military',
            value:'Харбий'
        },
        ],
        sudIxtisosligi:[
            {
                key:'criminal',
                value:'Жиноий'
            },
            {
                key:'economic',
                value:'Иқтисодий'
            },
            {
                key:'administrative',
                value:'Маъмурий'
            },
            {
                key:'civil',
                value:'Фуқаролик'
            },
            {
                key:'military',
                value:'Харбий'
            },
        ],
        positions:[],
        kimTomonidan:[
            {
            key:'judge_of_the_regional_court',
            value:'Вилоят судининг судьяси'
            },
            {
                key:'chairman_of_the_multi_member_district_court',
                value:'Кўп таркибли туман судининг раиси'
            },
            {
                key:'deputy_chairman_of_the_district_court',
                value:'Туман суди раисининг ўринбосари'
            },
            {
                key:'chairman_of_the_district_court',
                value:'Бир таркибли туман судининг раиси'
            },
            {
                key:'district_court_judge',
                value:'Туман судининг судьяси'
            },
            {
                key:'chairman_and_judge_of_the_military_court',
                value:'Ҳарбий суд раиси ва судьяси'
            },
        ],
        nationality:[
            {
                key:"Ўзбек",
                value:"Ўзбек"
            },
            {
                key:"Қирғиз",
                value:"Қирғиз"
            },
            {
                key:"Қозоқ",
                value:"Қозоқ"
            },
            {
                key:"Қорақалпоқ",
                value:"Қорақалпоқ"
            },
            {
                key:"Рус",
                value:"Рус"
            },
            {
                key:"Татар",
                value:"Татар"
            },
            {
                key:"Тожик",
                value:"Тожик"
            },
            {
                key:"Туркман",
                value:"Туркман"
            },
            {
                key:"Бошқа",
                value:"Бошқа"
            },
        ]
    },
    getters:{
        getRegions(state){
            return state.regions
        },
        getSudTuri(state){
            return state.sudTuri
        },
        getSudIxtisosligi(state){
            return state.sudIxtisosligi
        },
        getPositions(state){
            return state.positions
        },
        getKimTomonidan(state){
            return state.kimTomonidan
        },
        getNationality(state){
            return state.nationality
        }
     },
    mutations:{
        updateRegions(state, data){
            let array = [];
            array.push({
                key:'00s0eed0000region000014',
                value:'Тошкент шахри'
            })
            data.forEach(element => {
                let obj = {}
                obj.key=element.id
                obj.value = element.name_uz
                array.push(obj)
            });
            state.regions = array
        },
        updatePositions(state, data){
            let array = [];
            data.forEach(element => {
                let obj = {}
                obj.key=element.court_id
                obj.value = element.court_name_uz
                array.push(obj)
            });
            state.positions = array
        },
    },
    actions:{
        loadRegions(context){
            axios.post('https://e-justice.ai-softdev.com/api/v1/sud/main/',{
                url:"http://185.203.238.157:7889/api/region/select/get-provinces-list",
                access_token: localStorage.getItem('token'),
                data:{}
            }).then(response=>{
                context.commit('updateRegions', response.data.result)
            }).catch(e=>{
            })
        },
        loadPositions(context){
            axios.post('https://e-justice.ai-softdev.com/api/v1/sud/main/',{
                url:"http://185.203.238.157:7889/api/staff/select/list-staff",
                access_token: localStorage.getItem('token'),
                data:{}
            }).then(response=>{
                context.commit('updatePositions', response.data.result)
            }).catch(e=>{
                localStorage.clear()
            })
        }
    }
}
