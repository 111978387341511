<template>
  <div class="shadow-l-shadow rounded-3xl">
    <div
      class="bg-a-link-b items-center flex justify-between py-5 px-7 rounded-tl-3xl rounded-tr-3xl"
    >
      <div class="">
        <p class="text-xl text-white font-gilroy font-bold">
          Умумий маълумотлар
        </p>
        <p class="text-sm text-white font-gilroy">
          Судья хакида умумий маълумотлар
        </p>
      </div>
      <div class="">
        <div class="cursor-pointer" @click="loadPdf()">
          <img src="@/assets/pdf_icon.svg" alt="" />
        </div>
      </div>
    </div>
    <div class="">
      <v-profile-info-item-vue>
        <template v-slot:left>
          <p class="font-gilroy text-lg text-a-black-2">Миллати:</p></template
        >
        <template v-slot:right
          ><p class="font-gilroy text-lg text-a-black-2">
            {{ profileMain.nationality }}
          </p></template
        >
      </v-profile-info-item-vue>
      <v-profile-info-item-vue>
        <template v-slot:left>
          <p class="font-gilroy text-lg text-a-black-2">
            Туғилган санаси:
          </p></template
        >
        <template v-slot:right
          ><p class="font-gilroy text-lg text-a-black-2">
            {{ normal_date(profileMain.date_of_birth) }}
          </p></template
        >
      </v-profile-info-item-vue>

      <v-profile-info-item-vue>
        <template v-slot:left>
          <p class="font-gilroy text-lg text-a-black-2">
            Доимий рўйҳатда туриш манзили:
          </p></template
        >
        <template v-slot:right
          ><p class="font-gilroy text-lg text-a-black-2">
            {{ getRegion(profileMain.permanent_region_id) }}
          </p></template
        >
      </v-profile-info-item-vue>
      <v-profile-info-item-vue>
        <template v-slot:left>
          <p class="font-gilroy text-lg text-a-black-2">
            Яшаш манзили:
          </p></template
        >
        <template v-slot:right
          ><p class="font-gilroy text-lg text-a-black-2">
            {{ getRegion(profileMain.live_region_id) }}
          </p></template
        >
      </v-profile-info-item-vue>
      <v-profile-info-item-vue>
        <template v-slot:left>
          <p class="font-gilroy text-lg text-a-black-2">Маълумоти:</p></template
        >
        <template v-slot:right
          ><p class="font-gilroy text-lg text-a-black-2">
            {{ profileMain.special_study_place || "-" }}
          </p></template
        >
      </v-profile-info-item-vue>
      <v-profile-info-item-vue>
        <template v-slot:left>
          <p class="font-gilroy text-lg text-a-black-2">
            Тамомлаган олийгохи:
          </p></template
        >
        <template v-slot:right
          ><p class="font-gilroy text-lg text-a-black-2">
            {{ profileMain.study_place?.at(0) }}
          </p></template
        >
      </v-profile-info-item-vue>
      <v-profile-info-item-vue>
        <template v-slot:left>
          <p class="font-gilroy text-lg text-a-black-2">
            Мутахассислиги:
          </p></template
        >
        <template v-slot:right
          ><p class="font-gilroy text-lg text-a-black-2">
            {{ profileMain.speciality }}
          </p></template
        >
      </v-profile-info-item-vue>

      <v-profile-info-item-vue>
        <template v-slot:left>
          <p class="font-gilroy text-lg text-a-black-2">
            Партиявийлиги:
          </p></template
        >
        <template v-slot:right
          ><p class="font-gilroy text-lg text-a-black-2">
            {{ profileMain.partisanship }}
          </p></template
        >
      </v-profile-info-item-vue>
      <v-profile-info-item-vue>
        <template v-slot:left>
          <p class="font-gilroy text-lg text-a-black-2">Жинси:</p></template
        >
        <template v-slot:right
          ><p class="font-gilroy text-lg text-a-black-2">
            {{ profileMain.gender == "MALE" ? "Еркак" : "Аёл" }}
          </p></template
        >
      </v-profile-info-item-vue>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import vProfileInfoItemVue from "@/components/Admin/v-profile-info-item.vue";
export default {
  components: {
    vProfileInfoItemVue,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["profileMain"]),
  },
  methods: {
    ...mapActions(["loadPDF", "profileMain"]),
    loadPdf() {
      this.loadPDF(this.profileMain.id);
    },
    normal_date(date) {
      if (date) {
        let temp = date;
        temp = temp.split("-");
        return temp[2] + "." + temp[1] + "." + temp[0];
      } else return "";
    },
    getRegion(region) {
      if (region === "00s0eed0000region000001")
        return "Республика Каракалпакстан";
      else if (region === "00s0eed0000region000002") return "Андижон";
      else if (region === "00s0eed0000region000003") return "Бухоро";
      else if (region === "00s0eed0000region000004") return "Жиззах";
      else if (region === "00s0eed0000region000005") return "Кашкадарья";
      else if (region === "00s0eed0000region000006") return "Навои";
      else if (region === "00s0eed0000region000007") return "Наманган";
      else if (region === "00s0eed0000region000008") return "Самарканд";
      else if (region === "00s0eed0000region000009") return "Сурхандарья";
      else if (region === "00s0eed0000region000010") return "Сирдарья";
      else if (region === "00s0eed0000region000011") return "Тошкент вилояти";
      else if (region === "00s0eed0000region000012") return "Фарғона";
      else if (region === "00s0eed0000region000013") return "Хорезм";
      else if (region === "00s0eed0000region000014") return "Тошкент шахри";
      return "";
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped></style>
