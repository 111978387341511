<template>
  <header
    class="shadow z-50 fixed left-0 top-0 w-full transition duration-300 ease-linear"
    :class="{
      'h-full ': open,
      'bg-l-bg': scrollPosition > maxScroll + 100 && !open,
    }"
  >
    <aos-vue animation="fade-down" class="relative -z-10">
      <div
        class="container m-auto py-3 5 px-6 md:flex justify-between items-center"
      >
        <div
          class="flex items-center maxmd:w-full maxmd:justify-between cursor-pointer"
        >
          <a href="">
            <img
              v-if="!(scrollPosition > maxScroll + 100 && !open)"
              src="@/assets/logo.svg"
              alt=""
              class="z-20"
            />
            <img v-else src="@/assets/logo_2.svg" alt="" class="z-20" />
          </a>
          <button
            class="text-l-black w-10 h-10 relative focus:outline-none bg-l-white md:hidden"
            @click="open = !open"
          >
            <div
              class="block w-5 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2"
            >
              <span
                aria-hidden="true"
                class="block absolute h-0.5 w-5 bg-l-black transform transition duration-500 ease-in-out"
                :class="{ 'rotate-45': open, ' -translate-y-1.5': !open }"
              ></span>
              <span
                aria-hidden="true"
                class="block absolute h-0.5 w-5 bg-l-black transform transition duration-500 ease-in-out"
                :class="{ 'opacity-0': open }"
              ></span>
              <span
                aria-hidden="true"
                class="block absolute h-0.5 w-5 bg-l-black transform transition duration-500 ease-in-out"
                :class="{ '-rotate-45': open, ' translate-y-1.5': !open }"
              ></span>
            </div>
          </button>
        </div>

        <ul
          class="md:flex maxmd:h-screen maxmd:w-8/12 maxmd:pt-12 maxmd:top-0 lg:items-center md:px-0 px-3 md:pb-0 md:static absolute md:w-auto w-full top-14 duration-300 ease-in"
          :class="{
            'left-0  bg-l-bg   ': open,
            'left-[-100%] ': !open,
          }"
        >
          <li
            class="md:mx-4 md:my-0 my-6 flex items-center font-slalom"
            :class="{
              'xl:mr-32 lg:mr-20 md:mr-16 ': index !== links.length - 1,
              'text-white': scrollPosition > maxScroll + 100 && !open,
            }"
            v-for="(link, index) in links"
          >
            <a :href="link.link" @click="link.click">{{ link.name }}</a>
          </li>
          <div class="flex justify-between items-center">
            <span
              :class="{
                'text-white': scrollPosition > maxScroll + 100 && !open,
              }"
              @click="change()"
              class="cursor-pointer text-l-mc font-slalom text-2xl"
              >{{ lang }}</span
            >
            <SignInButton
              v-if="!isAuth"
              @click="
                modalActive();
                open = false;
              "
            >
              <img src="@/assets/Profile.svg" alt="" class="mr-1" />
              <span class="font-slalom"> {{ $t("enter") }}</span>
            </SignInButton>
            <SignInButton v-else @click="goAdmin()">
              Перейти в админку
            </SignInButton>
          </div>
        </ul>
      </div>
    </aos-vue>
  </header>
</template>
<script>
import SignInButton from "./SignInButton.vue";
import { mapGetters } from "vuex";
import router from "@/router";

export default {
  name: "l-header",
  computed: mapGetters(["isAuth"]),
  components: {
    SignInButton,
  },

  props: ["maxScroll", "modalActive", "modalHelp", "switchLanguage"],
  data() {
    return {
      scrollPosition: null,
      open: false,
      links: [
        { name: this.$t("project"), link: "#about_school", click: {} },
        { name: this.$t("help"), link: "#services", click: {} },
        { name: this.$t("support"), link: "#", click: this.modalHelp },
      ],
      lang: localStorage.getItem("locale") || "uz",
    };
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    goAdmin() {
      router.push("/admin/dashboard");
    },
    change() {
      this.switchLanguage();
      this.lang = localStorage.getItem("locale");
    },
  },
  mounted() {
    this.$store.dispatch("checkAuth");
    window.addEventListener("scroll", this.updateScroll);
  },
  watch: {
    lang() {
      this.links = [
        { name: this.$t("project"), link: "#about_school", click: {} },
        { name: this.$t("help"), link: "#services", click: {} },
        { name: this.$t("support"), link: "#", click: this.modalHelp },
      ];
    },
  },
};
</script>
<style scoped></style>
