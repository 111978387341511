<template>
  <div class="flex flex-wrap">
    <input
      v-if="check()"
      :type="type"
      :placeholder="placeholder"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      class="shadow-l-i-shadow cursor-pointer text-a-input form-select form-select-lg appearance-none block w-full px-4 py-4 text-sm font-normal bg-white bg-clip-padding bg-no-repeat border border-solid border-l-i-text rounded-xl transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
    />
    <textarea
      v-else
      :placeholder="placeholder"
      :value="modelValue"
      class="resize-none shadow-l-i-shadow cursor-pointer text-a-input form-select form-select-lg appearance-none block w-full px-4 py-4 text-sm font-normal bg-white bg-clip-padding bg-no-repeat border border-solid border-l-i-text rounded-xl transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
      @input="$emit('update:modelValue', $event.target.value)"
      name=""
      id=""
      cols="30"
      rows="5"
    ></textarea>
  </div>
</template>

<script>
export default {
  props: ["modelValue", "type", "label", "placeholder"],
  methods: {
    check() {
      return this.type != "textarea";
    },
  },
};
</script>

<style lang="scss" scoped></style>
