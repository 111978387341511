<template>
  <div class="flex w-full justify-between">
    <div class="w-4/12 shadow-l-shadow h-max">
      <div class="flex justify-between p-5 border-b border-a-profile-border">
        <div class="w-4/12">
          <img
            class="w-full rounded-xl h-full"
            :src="'data:image/png;base64,' + profileImg"
            alt=""
          />
        </div>
        <div class="w-7/12">
          <p class="font-gilroy text-xl text-a-black">
            {{ profileMain.full_name }}
          </p>
          <p class="text-a-gray font-gilroy my-3">
            {{ profileMain.position_name?.name_uz }}
          </p>
          <p class="px-4 py-2 bg-a-link-b rounded-2xl text-white">
            {{ getDate() }}
          </p>
        </div>
      </div>
      <div class="px-4 py-5">
        <router-link :to="{ name: 'main' }">
          <v-profile-link-vue :active="CurrentRoute('main')">
            <template v-slot:icon
              ><svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.83398 11.0007C1.83398 7.57373 1.83398 5.86026 2.57084 4.58398C3.05357 3.74788 3.74788 3.05357 4.58398 2.57084C5.86026 1.83398 7.57373 1.83398 11.0007 1.83398C14.4276 1.83398 16.141 1.83398 17.4173 2.57084C18.2534 3.05357 18.9477 3.74788 19.4305 4.58398C20.1673 5.86026 20.1673 7.57373 20.1673 11.0007C20.1673 14.4276 20.1673 16.141 19.4305 17.4173C18.9477 18.2534 18.2534 18.9477 17.4173 19.4305C16.141 20.1673 14.4276 20.1673 11.0007 20.1673C7.57373 20.1673 5.86026 20.1673 4.58398 19.4305C3.74788 18.9477 3.05357 18.2534 2.57084 17.4173C1.83398 16.141 1.83398 14.4276 1.83398 11.0007ZM11.7187 6.71777C11.7187 7.11434 11.3972 7.43583 11.0007 7.43583C10.6041 7.43583 10.2826 7.11434 10.2826 6.71777C10.2826 6.3212 10.6041 5.99972 11.0007 5.99972C11.3972 5.99972 11.7187 6.3212 11.7187 6.71777ZM11.6423 9.47284C11.6423 9.11846 11.355 8.83118 11.0006 8.83118C10.6462 8.83118 10.3589 9.11846 10.3589 9.47284V14.5654C10.3589 14.9198 10.6462 15.2071 11.0006 15.2071C11.355 15.2071 11.6423 14.9198 11.6423 14.5654V9.47284Z"
                  fill="white"
                />
              </svg>
            </template>
            <template v-slot:text>Умумий маълумотлар</template>
          </v-profile-link-vue>
        </router-link>
        <router-link :to="{ name: 'mehnat' }">
          <v-profile-link-vue :active="CurrentRoute('mehnat')">
            <template v-slot:icon
              ><svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.81273 3.22689C9.19826 3.22689 8.67549 3.65389 8.52875 4.22941H13.4629C13.3161 3.65389 12.7934 3.22689 12.1789 3.22689H9.81273ZM14.8572 4.22891H16.6731C18.599 4.22891 20.1673 5.81624 20.1673 7.7656C20.1673 7.7656 20.1123 8.59083 20.094 9.74002C20.0921 9.83099 20.0481 9.9201 19.9756 9.97394C19.5345 10.2998 19.131 10.569 19.0943 10.5875C17.5719 11.6086 15.8027 12.3271 13.918 12.6845C13.7951 12.7086 13.6741 12.6446 13.6117 12.535C13.0834 11.6198 12.0966 11.0238 10.9961 11.0238C9.90285 11.0238 8.90686 11.6133 8.363 12.5295C8.29972 12.6371 8.18049 12.6993 8.05851 12.6761C6.18941 12.3178 4.42028 11.6003 2.90702 10.5968L2.02658 9.98416C1.95321 9.93774 1.90735 9.8542 1.90735 9.76137C1.87984 9.28796 1.83398 7.7656 1.83398 7.7656C1.83398 5.81624 3.40227 4.22891 5.32823 4.22891H7.13497C7.30922 2.88292 8.43729 1.83398 9.81298 1.83398H12.1792C13.5548 1.83398 14.6829 2.88292 14.8572 4.22891ZM19.8558 11.7476L19.8191 11.7662C17.9665 13.0101 15.7379 13.8362 13.3992 14.1797C13.0691 14.2261 12.7389 14.0126 12.6472 13.6784C12.4454 12.9172 11.7943 12.416 11.0147 12.416H11.0055H10.9872C10.2076 12.416 9.55648 12.9172 9.35471 13.6784C9.263 14.0126 8.93284 14.2261 8.60267 14.1797C6.264 13.8362 4.03539 13.0101 2.18279 11.7662C2.17362 11.7569 2.08191 11.7012 2.00854 11.7476C1.926 11.794 1.926 11.9054 1.926 11.9054L1.9902 16.6396C1.9902 18.5889 3.54931 20.167 5.47527 20.167H16.5175C18.4434 20.167 20.0025 18.5889 20.0025 16.6396L20.0759 11.9054C20.0759 11.9054 20.0759 11.794 19.9934 11.7476C19.9475 11.7198 19.8925 11.7291 19.8558 11.7476ZM11.6842 15.6366C11.6842 16.0265 11.3815 16.3328 10.9963 16.3328C10.6203 16.3328 10.3085 16.0265 10.3085 15.6366V14.4391C10.3085 14.0586 10.6203 13.7429 10.9963 13.7429C11.3815 13.7429 11.6842 14.0586 11.6842 14.4391V15.6366Z"
                  fill="white"
                />
              </svg>
            </template>
            <template v-slot:text>Мехнат</template>
          </v-profile-link-vue>
        </router-link>
        <router-link :to="{ name: 'rating' }">
          <v-profile-link-vue :active="CurrentRoute('rating')">
            <template v-slot:icon
              ><svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.4263 13.126C16.1889 13.3561 16.0798 13.6888 16.1339 14.0152L16.9488 18.5252C17.0175 18.9074 16.8562 19.2943 16.5363 19.5152C16.2228 19.7444 15.8057 19.7719 15.4638 19.5885L11.4039 17.471C11.2627 17.3958 11.106 17.3555 10.9455 17.3509H10.6971C10.611 17.3638 10.5266 17.3913 10.4496 17.4334L6.38879 19.561C6.18804 19.6618 5.9607 19.6976 5.73795 19.6618C5.19529 19.5592 4.8332 19.0422 4.92212 18.4968L5.73795 13.9868C5.79204 13.6577 5.68295 13.3231 5.44554 13.0893L2.13545 9.88102C1.85862 9.61243 1.76237 9.2091 1.88887 8.84518C2.0117 8.48218 2.3252 8.21727 2.70379 8.15768L7.25962 7.49677C7.60612 7.46102 7.91045 7.25018 8.06629 6.93852L10.0738 2.82268C10.1215 2.73102 10.1829 2.64668 10.2571 2.57518L10.3396 2.51102C10.3827 2.46335 10.4322 2.42393 10.4872 2.39185L10.5871 2.35518L10.743 2.29102H11.1289C11.4735 2.32677 11.777 2.53302 11.9355 2.84102L13.9696 6.93852C14.1163 7.23827 14.4014 7.44635 14.7305 7.49677L19.2863 8.15768C19.6713 8.21268 19.993 8.47852 20.1205 8.84518C20.2405 9.21277 20.137 9.6161 19.8546 9.88102L16.4263 13.126Z"
                  fill="white"
                />
              </svg>
            </template>
            <template v-slot:text>Рейтинг</template>
          </v-profile-link-vue>
        </router-link>
      </div>
    </div>
    <div class="w-7/12">
      <router-view />
    </div>
  </div>
</template>
<script>
import router from "@/router";

import vProfileLinkVue from "@/components/Admin/v-profile-link.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Profile",
  data() {
    return {
      date: "",
    };
  },
  computed: {
    ...mapGetters(["profileImg", "profileMain"]),
  },

  mounted() {
    this.loadUserImage(this.$router.currentRoute.value.params.id);
    this.loadMainInfo(this.$router.currentRoute.value.params.id);
  },

  components: {
    vProfileLinkVue,
  },
  methods: {
    ...mapActions(["loadUserImage", "loadMainInfo"]),
    CurrentRoute(name) {
      return this.$router.currentRoute.value.name === name;
    },
    getDate() {
      if (Object.keys(this.profileMain).length !== 0) {
        let temp = this.profileMain?.staff_history.start_date;
        temp = temp.split("-");
        let date = "";
        date += temp[2] + "." + temp[1] + "." + temp[0];
        temp = this.profileMain?.staff_history.finish_date;
        temp = temp.split("-");

        date += " - " + temp[2] + "." + temp[1] + "." + temp[0];
        return date;
      }
      return "";
    },
  },
};
</script>
<style>
.active {
  color: #2e6df3 !important;
  border-color: #2e6df3 !important;
}
.page-item {
  padding: 4px 12px;
  border: 1px solid #b3b6bc;
  border-radius: 4px;
  cursor: pointer;
}
</style>
