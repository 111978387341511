<template>
  <div class="flex min-h-[34px]">
    <div
      class="text-a-table-h font-slalom w-10/12 border border-a-table-border px-2 py-1"
    >
      {{ text }}
    </div>
    <div
      class="flex items-center justify-center text-a-table-h font-slalom fonst-bold w-1/12 border-t border-b border-a-table-border text-center"
    >
      {{ left }}
    </div>
    <div
      class="flex items-center justify-center font-slalom fonst-bold w-1/12 border border-a-table-border text-center"
      :class="{
        'text-a-red': parseInt(type) === -1,
        'text-a-table-h': parseInt(type) === 0,
        'text-a-green': parseInt(type) === 1,
      }"
    >
      {{ right }}
    </div>
  </div>
</template>

<script>
export default {
  props: ["text", "left", "right", "type"],
};
</script>

<style lang="scss" scoped></style>
