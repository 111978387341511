<template>
  <div>
    <div class="bg-l-white accordion w-full flex items-center flex-wrap my-10">
      <div
        @click="isOpen = !isOpen"
        class="hover:text-white text-l-ac w-full flex items-center py-4 px-2 border-2 border-l-ac relative transition-all duration-300 hover:bg-l-ac"
      >
        <h1 class="mx-6 md:text-3xl maxmd:text-xl font-kyiv">{{ index }}</h1>
        <h1
          class="md:text-3xl maxmd:text-xl maxmd:mr-14 font-kyiv pr-16 break-words"
        >
          {{ item.title }}
        </h1>
        <div
          :class="{
            'bg-l-ac text-white': isOpen,
            'bg-white text-l-ac': !isOpen,
          }"
          class="h-full w-16 flex items-center absolute left-full top-1/2 origin-center text-l-ac transition-transform duration-500 transform -translate-x-full -translate-y-1/2"
        >
          <span
            aria-hidden="true"
            class="block absolute h-0.5 w-5 left-1/2 -translate-x-1/2 -translate-y-0.5 origin-center transform transition-all top-1/2 duration-500 ease-in-out"
            :class="{
              'rotate-45 bg-white': isOpen,
              'bg-l-ac': !isOpen,
            }"
          ></span>

          <span
            aria-hidden="true"
            class="block absolute left-1/2 h-0.5 w-5 -translate-x-1/2 -translate-y-1/2 transform origin-center transition-all top-1/2 rotate-90 duration-500 ease-in-out"
            :class="{
              'rotate-135 left-1/3 bg-white': isOpen,
              'bg-l-ac': !isOpen,
            }"
          ></span>
        </div>
      </div>

      <div
        class="max-h-0 w-full overflow-hidden transition-all duration-500"
        :class="{ 'max-h-screen border-x-2 border-b-2 border-l-ac ': isOpen }"
      >
        <p class="text-l-ac p-10 text-2xl font-gilroy">
          {{ item.body }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["item", "index"],
  data() {
    return {
      isOpen: false,
    };
  },
};
</script>
<style scoped>
.rotate-135 {
  transform: rotate(135deg);
}
.accordion {
}
</style>
