<template>
  <div class="flex flex-wrap">
    <label class="w-full text-a-text font-bold text-base">{{ text }}</label>
    <select
      @change="$emit('update:modelValue', $event.target.value)"
      name=""
      id=""
      :value="modelValue"
      class="cursor-pointer form-select form-select-lg appearance-none block w-full px-4 py-4 text-sm font-normal bg-white bg-clip-padding bg-no-repeat border border-solid border-a-text rounded-3xl transition ease-in-out m-0 focus:text-a-text focus:bg-white focus:outline-none"
      aria-label=".form-select-lg example"
    >
      <option value="" disabled v-if="text">{{ text }}</option>

      <option :value="item.key" :key="item.key" v-for="item in items">
        {{ item.value }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "v-select",
  props: ["items", "modelValue", "text"],
};
</script>

<style></style>
