<template>
  <div
    class="flex flex-wrap max-w-xs border-r h-full border-r-l-border px-6 my-3"
  >
    <h3 class="font-bold text-3xl text-center w-full font-kyiv h-full">
      {{ item.header }}
    </h3>
    <h1 class="text-l-mc text-7xl text-center w-full font-bold font-kyiv">
      <number
        animationPaused
        ref="number"
        :from="0"
        :to="item.num"
        :format="theFormat"
        :duration="3"
        easing="Power1.easeOut"
      />
    </h1>
    <h4 class="text-l-mc text-2xl font-normal text-center w-full font-kyiv">
      {{ item.bottom }}
    </h4>
  </div>
</template>
<script>
export default {
  name: "InfoBLock",
  computed() {},
  props: ["item"],
  data() {
    return {
      scrollPosition: null,
      maxScroll: null,
      played: false,
    };
  },
  methods: {
    theFormat(number) {
      return number.toFixed(0);
    },
    playAnimation() {
      this.$refs.number.play();
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
      if (this.played === false) {
        if (this.scrollPosition > this.maxScroll - 300) {
          this.playAnimation();
          this.played = true;
        }
      }
    },
  },
  mounted() {
    this.maxScroll = document.querySelector("#info-scroll").offsetTop;
    window.addEventListener("scroll", this.updateScroll);
    this.scrollPosition = window.scrollY;
    if (window.outerHeight > this.maxScroll + 260) {
      this.playAnimation();
      this.played = true;
    }
  },
  components: {},
};
</script>
