<template>
  <router-link
    :to="{ name: 'main', params: { id: item.id } }"
    class="flex items-center overflow-x-auto maxmd:max-w-7xl w-full"
  >
    <p
      class="text-base font-dmsans border w-1/12 maxmd:w-20 h-full text-a-link border-a-border border-l-0 border-t-0 px-10 py-2.5 justify-between items-center flex"
    >
      {{ number }}
    </p>
    <p
      class="font-dmsans items-center w-2/12 maxmd:w-72 flex text-base text-black p-2.5 border border-a-border border-t-0 border-l-0 h-full"
    >
      {{ item.full_name }}
    </p>
    <p
      class="font-dmsans items-center w-2/12 flex maxmd:w-96 text-base text-black p-2.5 border border-a-border border-l-0 border-t-0 h-full"
    >
      {{ item.details?.position_name_uz }}
    </p>
    <p
      class="font-dmsans items-center justify-center w-1/12 flex maxmd:w-24 text-base text-black p-2.5 border border-a-border border-l-0 border-t-0 h-full"
    >
      {{ item.nationality }}
    </p>
    <p
      class="font-dmsans text-base maxmd:w-36 w-2/12 text-black flex flex-wrap p-2.5 border border-a-border border-l-0 border-t-0 h-full"
    >
      <span class="font-dmsans w-full"
        >дан: {{ item.details?.start_date }}</span
      >
      <span class="font-dmsans w-full"
        >гача: {{ item.details?.finish_date }}</span
      >
    </p>
    <p
      class="justify-center items-center maxmd:w-44 w-2/12 text-base text-black flex flex-wrap p-2.5 border border-a-border border-l-0 border-t-0 h-full"
    >
      <span class="font-dmsans w-full">
        {{ item.date_of_birth }}
      </span>
      <span class="font-dmsans w-full">{{ item.place_of_birth?.name_uz }}</span>
    </p>
    <p
      class="font-dmsans justify-center text-base maxmd:w-20 items-center w-1/12 flex text-black p-2.5 text-center border border-a-border border-l-0 border-t-0 h-full"
    >
      {{ item.work_experience_in_years }}
    </p>
    <p
      class="font-dmsans justify-center text-base maxmd:w-48 items-center flex w-1/12 text-black p-2.5 text-center border border-a-border border-l-0 border-t-0 h-full border-r-0"
    >
      <span
        class="py-2 px-4 rounded-3xl"
        :class="getColor(item.profile_rating)"
      >
        {{ getNum(item.profile_rating) }}
      </span>
    </p>
  </router-link>
</template>

<script>
export default {
  name: "judge",

  props: ["number", "item"],
  methods: {
    getColor(value) {
      let n = parseInt(value);
      if (n <= 40) return "bg-s-red text-s-t-red";
      if (n > 40 && n < 70) return "bg-s-yellow text-s-t-yellow";
      return "bg-s-green text-s-t-green";
    },
    getNum(value) {
      return parseInt(value).toFixed(1);
    },
    navigate(item) {
      this.$router.push({
        name: "main",
        params: {
          id: item.id,
        },
      });
      // this.$router.push({
      //   name: "main",
      //   params: {
      //     id: item.id,
      //     data: item,
      //   },
      // });
    },
  },
};
</script>

<style></style>
