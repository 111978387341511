<template>
  <div
    class="py-4 px-7 border-b border-b-a-profile-border flex justify-between"
  >
    <slot name="left"></slot>
    <slot name="right"></slot>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
